import { useState, useEffect } from 'react';
import { Input, NativeSelect, Stack, TextInput, Divider, SimpleGrid } from '@mantine/core';
import { evalFhirPath } from '@iehr/core';
import { Address, Patient } from '@iehr/fhirtypes';
import { PlaceAutocomplete } from './GooglePlaceAutocomplete';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { DateInput } from './DateInput';

export function fixPatient(p: Patient) {
  if (!p) {
    return undefined;
  }
  const np: any = {
    resourceType: p.resourceType,
    id: p.id,
    name: p.name,
    gender: p.gender,
    birthDate: p.birthDate,
    telecom: p.telecom,
    address: p.address,
    contact: p.contact,
  };

  Object.keys(np).forEach((key) => (!np[key] ? delete np[key] : {}));

  return np as Patient;
}

export interface PatientInputProps {
  readonly defaultValue: Patient;
  readonly onChange: (patient: Patient | undefined) => void;
}

export function PatientInput({ defaultValue, onChange }: PatientInputProps): JSX.Element {
  const defaultPatient = fixPatient(defaultValue);
  const related = !!defaultPatient?.contact;
  const [patient, setPatient] = useState<Patient | undefined>(defaultPatient);
  const [firstName, setFirstName] = useState<string | undefined>(
    evalFhirPath('name.first().given.first()', defaultValue)[0] as string
  );
  const [lastName, setLastName] = useState<string | undefined>(
    evalFhirPath('name.first().family', defaultValue)[0] as string
  );
  const [gender, setGender] = useState<'female' | 'male' | 'unknown' | undefined>(
    evalFhirPath('gender', defaultValue)[0] as 'female' | 'male' | 'unknown'
  );
  const [dob, setDoB] = useState<string | undefined>(evalFhirPath('birthDate', defaultValue)[0] as string);
  const [phone, setPhone] = useState<string | undefined>(
    evalFhirPath("telecom.where(system='phone').value", defaultValue)[0] as string
  );
  const [email, setEmail] = useState<string | undefined>(
    evalFhirPath("telecom.where(system='email').value", defaultValue)[0] as string
  );
  const [address, setAddress] = useState<Address | undefined>(evalFhirPath('address', defaultValue)[0] as Address);
  const [contactFirstName, setContactFirstName] = useState<string | undefined>(
    evalFhirPath('contact.name.first().given.first()', defaultValue)[0] as string
  );
  const [contactLastName, setContactLastName] = useState<string | undefined>(
    evalFhirPath('contact.name.first().family', defaultValue)[0] as string
  );
  const [contactPhone, setContactPhone] = useState<string | undefined>(
    evalFhirPath("contact.telecom.where(system='phone').value", defaultValue)[0] as string
  );
  const [contactEmail, setContactEmail] = useState<string | undefined>(
    evalFhirPath("contact.telecom.where(system='email').value", defaultValue)[0] as string
  );
  const [contactAddress, setContactAddress] = useState<Address | undefined>(defaultPatient?.contact?.[0]?.address);
  //const [initialized, setInitilized] = useState<boolean>(false);
  //const [ready, setReady] = useState<boolean>(false);

  //console.log('PatientInput',phone);

  useEffect(() => {
    onChange(patient);
  }, [patient]);

  useEffect(() => {
    if (
      firstName &&
      lastName &&
      gender &&
      dob &&
      email &&
      isEmailValid(email) &&
      phone &&
      isPhoneValid(phone) &&
      address
    ) {
      const newPatient: Patient = {
        resourceType: 'Patient',
        name: [{ given: [firstName], family: lastName }],
        gender: gender,
        birthDate: dob,
        telecom: [
          { system: 'email', value: email },
          { system: 'phone', value: phone },
        ],
        address: [address],
      };

      if (defaultValue?.id) {
        newPatient.id = defaultValue.id;
      }
      setPatient(newPatient);
      return;
    } else if (
      firstName &&
      lastName &&
      gender &&
      dob &&
      contactFirstName &&
      contactLastName &&
      contactEmail &&
      isEmailValid(contactEmail) &&
      contactPhone &&
      isPhoneValid(contactPhone) &&
      contactAddress
    ) {
      const newPatient: Patient = {
        resourceType: 'Patient',
        name: [{ given: [firstName], family: lastName }],
        gender: gender,
        birthDate: dob,
        contact: [
          {
            name: { given: [contactFirstName], family: contactLastName },
            telecom: [
              { system: 'email', value: contactEmail },
              { system: 'phone', value: contactPhone },
            ],
            address: contactAddress,
          },
        ],
      };
      setPatient(newPatient);
      return;
    } else {
      setPatient(undefined);
    }
  }, [
    firstName,
    lastName,
    gender,
    dob,
    email,
    phone,
    address,
    contactFirstName,
    contactLastName,
    contactEmail,
    contactPhone,
    contactAddress,
    defaultValue,
  ]);

  return (
    <Stack>
      <SimpleGrid cols={{ base: 1, xs: 2 }}>
        <TextInput
          key="first"
          label={`${related ? 'Patient ' : ''}First Name`}
          error={!firstName}
          withErrorStyles={false}
          required
          defaultValue={firstName}
          onChange={(e) => {
            setFirstName(e.currentTarget.value);
          }}
        />
        <TextInput
          key="last"
          label={`${related ? 'Patient ' : ''}Last Name`}
          error={!lastName}
          withErrorStyles={false}
          required
          defaultValue={lastName}
          onChange={(e) => {
            setLastName(e.currentTarget.value);
          }}
        />
      </SimpleGrid>

      <SimpleGrid cols={{ base: 1, xs: 2 }}>
        <DateInput
          required
          label={`${related ? 'Patient ' : ''}Date of Birth`}
          defaultValue={dob}
          onChange={(e) => {
            setDoB(e);
          }}
        />

        <NativeSelect
          required
          label={`${related ? 'Patient ' : ''}Gender`}
          error={!gender}
          withErrorStyles={false}
          data={['', 'female', 'male', 'unknown']}
          defaultValue={gender}
          onChange={(e) => {
            setGender(e.currentTarget.value as 'female' | 'male' | 'unknown');
          }}
        />
      </SimpleGrid>
      {!related && (
        <>
          <SimpleGrid cols={{ base: 1, xs: 2 }}>
            <Input.Wrapper
              label="Email"
              required
              error={!isEmailValid(email!) ? (email ? 'Please enter a valid email number' : ' ') : undefined}
            >
              <TextInput
                placeholder="name@domin.com"
                required
                value={email}
                onChange={(e) => {
                  setEmail(e.currentTarget.value);
                }}
              />
            </Input.Wrapper>
            <Input.Wrapper
              label="Mobile"
              required
              error={!isPhoneValid(phone!) ? (phone ? 'Please enter a valid phone number' : ' ') : undefined}
            >
              <PhoneInput
                defaultCountry={'ie'}
                forceDialCode
                value={phone}
                onChange={(phone, meta) => {
                  setPhone(meta.inputValue);
                }}
              />
            </Input.Wrapper>
          </SimpleGrid>

          <PlaceAutocomplete
            label="Address"
            defaultValue={JSON.stringify(address)}
            onChange={(place) => {
              setAddress(place ? JSON.parse(place) : undefined);
            }}
            options={{
              types: ['address'],
              fields: ['address_components', 'geometry'],
              componentRestrictions: {
                country: ['ie'],
              },
            }}
          />
        </>
      )}

      {related && (
        <>
          <Divider my="md" />
          <SimpleGrid cols={{ base: 1, xs: 2 }}>
            <TextInput
              label={`You First Name`}
              error={!contactFirstName}
              withErrorStyles={false}
              required
              defaultValue={contactFirstName}
              onChange={(e) => {
                setContactFirstName(e.currentTarget.value);
              }}
            />

            <TextInput
              label={`You Last Name`}
              error={!contactLastName}
              withErrorStyles={false}
              required
              defaultValue={contactLastName}
              onChange={(e) => {
                setContactLastName(e.currentTarget.value);
              }}
            />
          </SimpleGrid>
          <SimpleGrid cols={{ base: 1, xs: 2 }}>
            <Input.Wrapper
              label="Your Email"
              required
              error={
                !isEmailValid(contactEmail!) ? (contactEmail ? 'Please enter a valid email number' : ' ') : undefined
              }
            >
              <TextInput
                placeholder="name@domain.com"
                required
                value={contactEmail}
                onChange={(e) => {
                  setContactEmail(e.currentTarget.value);
                }}
              />
            </Input.Wrapper>

            <Input.Wrapper
              label="Your Mobile"
              required
              error={
                !isPhoneValid(contactPhone!) ? (contactPhone ? 'Please enter a valid phone number' : ' ') : undefined
              }
            >
              <PhoneInput
                defaultCountry={'ie'}
                required
                forceDialCode
                value={contactPhone}
                onChange={(e) => {
                  console.log(e);
                  setContactPhone(e);
                }}
              />
            </Input.Wrapper>
          </SimpleGrid>
          <PlaceAutocomplete
            label="Your Address"
            defaultValue={JSON.stringify(contactAddress)}
            onChange={(place) => {
              setContactAddress(place ? JSON.parse(place) : undefined);
            }}
            options={{
              types: ['address'],
              fields: ['address_components', 'geometry'],
              componentRestrictions: {
                country: ['ie'],
              },
            }}
          />
        </>
      )}
    </Stack>
  );
}

const phoneUtil = PhoneNumberUtil.getInstance();
const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};
function isEmailValid(email: string): boolean {
  const validEmailRegex =
    /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  if (!email) return false;

  const emailParts = email.split('@');

  if (emailParts.length !== 2) return false;

  const account = emailParts[0];
  const domain = emailParts[1];

  if (account.length > 64) return false;
  else if (domain.length > 255) return false;

  const domainParts = domain.split('.');

  if (domainParts.some((part) => part.length > 63)) return false;

  return validEmailRegex.test(email);
}
