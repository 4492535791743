import { useParams, useSearchParams } from "react-router-dom";
import { ConfirmQuestionnaireResponse } from '@onlinedoc/react';
import { Loading } from "@iehr/react";


export function ConfirmQuestionnaireResponsePage(): JSX.Element | null {
    const {resourceId} = useParams();
    const [searchParams] = useSearchParams();
    if(!resourceId || !searchParams ){
        return <Loading />
    }
    return <ConfirmQuestionnaireResponse resourceId={resourceId} searchParams={searchParams}/>;
}