import { BackgroundImage, Box, SimpleGrid } from '@mantine/core';
import { RegisterForm } from '@iehr/react';
import { useNavigate } from 'react-router-dom';
import { getConfig } from '../config';
import { Logo } from '@onlinedoc/react';

export function RegisterPage(): JSX.Element {
  const navigate = useNavigate();
  const config = getConfig();

  return (
    <BackgroundImage
      h="calc( 100dvh + 64px)"
      pt={100}
      pb={200}
      top={-64}
      pos="absolute"
      src="https://www.onlinedoc.ie/assets/images/office-about-1.jpg"
    >
      <RegisterForm
        type="patient"
        projectId={config.projectId}
        googleClientId={config.googleClientId}
        recaptchaSiteKey={config.recaptchaSiteKey}
        onSuccess={() => navigate('/')}
      >
        <Logo size={96} />
        <h2>Register with OnlineDoc</h2>
      </RegisterForm>
    </BackgroundImage>
  );
}
