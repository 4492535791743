import { Divider, SimpleGrid, Stack, TextInput, Title } from '@mantine/core';
import { Container, Loading, useIEHR } from '@iehr/react';
import { PlanDefinition } from '@iehr/fhirtypes';
import { useCallback, useEffect, useState } from 'react';
import { capitalize } from '@iehr/core';
import { PlanDefinitionRow } from './PlanDefinitionRow';
import { IconSearch } from '@tabler/icons-react';

export interface PlanDefinitionsProps {
  readonly category?: string;
  readonly noHeader?: boolean;
  readonly noCol?: boolean;
  readonly noSubtitle?: boolean;
}

export function PlanDefinitions({ category,noHeader,noCol,noSubtitle }: PlanDefinitionsProps): JSX.Element | null {
  const iehr = useIEHR();
  const [planDefinitions, setPlanDefinitions] = useState<PlanDefinition[]>();
  const [searchResult, setSearchResult] = useState<PlanDefinition[]>();
  const [title, setTitle] = useState<string[]>();

  useEffect(() => {
    if (!iehr) {
      return;
    }

    if (!category) {
      console.log('PlanDefinitions general');
      iehr
        .searchResources(
          'PlanDefinition',
          `_count=100&_fields=name,title,subtitle,relatedArtifact&_sort=title&status=active&_sort=title`
        )
        .then(setPlanDefinitions);
    } else {
      iehr
        .searchResources(
          'PlanDefinition',
          `_count=100&_fields=name,title,subtitle,relatedArtifact&_sort=title&status=active&topic=${category}`
        )
        .then(setPlanDefinitions);
    }
  }, [iehr, category]);

  useEffect(() => {
    if (!iehr || !planDefinitions) {
      return;
    }
    setTitle(category ? category.split(',').map((s) => capitalize(s)) : ['Services']);
    setSearchResult(planDefinitions);
  }, [iehr, planDefinitions]);

  const handleOnChange = useCallback(
    (search: string) => {
      console.log(search);
      if (!search) {
        setSearchResult(planDefinitions);
      } else {
        const result = planDefinitions?.filter(
          (pd) => pd.title?.toLowerCase().includes(search) || pd.subtitle?.toLowerCase().includes(search)
        );
        console.log(result);
        setSearchResult(result);
      }
    },
    [planDefinitions]
  );

  if (!planDefinitions?.length) {
    return <Loading />;
  }

  return (
    <Container>
      <Stack  p={32}>
        {!noHeader && <>
          <SimpleGrid cols={{ base: 1, sm: 2}}>
          <Title order={4}>{title?.join(', ')}</Title>
          <TextInput

            leftSection={<IconSearch />}
            placeholder="Search..."
            onChange={(e) => handleOnChange(e.currentTarget.value)}
          ></TextInput>
        </SimpleGrid>
        <Divider />
        </>}


        <SimpleGrid
          cols={{ base: 1, sm: noCol?1:2, md: noCol?1:3 }}
          spacing={{ base: 10, sm: 'xl' }}
          verticalSpacing={{ base: noSubtitle?'sm':'md', sm: noSubtitle?'sm':'xl' }}
        >
          {searchResult?.map((pd) => (
            <PlanDefinitionRow key={pd.id} noSubtitle={noSubtitle} planDefinition={pd} />
          ))}
        </SimpleGrid>
      </Stack>      
    </Container>

  );
}
