import { Button, Title } from '@mantine/core';
import { useState } from 'react';
import { PaymentElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { useIEHR } from '@iehr/react';
import { ContentType } from '@iehr/core';
import classes from './Stripe.module.css';
import { useStripePromise } from './StripeProvider.context';

export interface StripeOptions {
  readonly intent: {
    readonly amount: number;
    readonly currency: string;
    readonly customer: string;
    readonly description?: string;
    readonly metadata?: {
      resource_reference: string;
    };
  };
  readonly customer: {
    readonly name: string;
    readonly email: string;
  };
}

export interface StripeElementsProps {
  readonly mode: 'payment' | 'setup' | 'subscription';
  readonly stripeOptions: StripeOptions;
  readonly return_url: string;
}

export function StripeElements(props: StripeElementsProps): JSX.Element | null {
  const stripePromise = useStripePromise();
  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: props.mode,
        amount: props.stripeOptions.intent.amount,
        currency: props.stripeOptions.intent.currency,
      }}
    >
      <CheckoutForm return_url={props.return_url} stripeOption={props.stripeOptions} />
    </Elements>
  );
}

export interface CheckoutFormProps {
  readonly stripeOption: StripeOptions;
  readonly return_url: string;
}

export function CheckoutForm(props: CheckoutFormProps): JSX.Element | null {
  const stripeOptions = props.stripeOption;
  const iehr = useIEHR();
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string>();

  async function handleSubmit(event: any): Promise<void> {
    event.preventDefault();

    setErrorMessage('');

    if (!stripe || !elements) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    const { client_secret: clientSecret } = await iehr.post(
      'stripe/createpaymentintents',
      stripeOptions,
      ContentType.JSON
    );

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: props.return_url,
      },
      // Uncomment below if you only want redirect for redirect-based payments
      //redirect: 'if_required',
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <Button className={classes.button} type="submit" disabled={!stripe || !elements}>
          Pay {stripeOptions.intent.amount / 100} {stripeOptions.intent.currency.toUpperCase()}
        </Button>
      </form>

      {errorMessage && (
        <Title c="red" order={3}>
          {errorMessage}
        </Title>
      )}
    </div>
  );
}
