import { Card, Group, Stack, Text, Title } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { PlanDefinition, Reference } from '@iehr/fhirtypes';
import { useResource } from '@iehr/react';
import { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';

export interface PlanDefinitionRowProps {
  readonly onClick?: () => void;
  readonly planDefinition: PlanDefinition | Reference<PlanDefinition>;
  readonly noSubtitle?: boolean;
}

export function PlanDefinitionRow(props: PlanDefinitionRowProps): JSX.Element | null {
  const navigate = useNavigate();
  const planDefinition = useResource(props.planDefinition);
  const [iconUrl, setIconUrl] = useState<string>();

  useEffect(() => {
    if (!planDefinition || !planDefinition.relatedArtifact) {
      return;
    }
    setIconUrl(planDefinition.relatedArtifact.find((ra) => ra.label === 'icon')?.document?.url!);
  }, [planDefinition]);

  if (!planDefinition) {
    return null;
  }

  return (
    <Card
      h={props.noSubtitle ? 48 : 96}
      shadow="lg"
      padding={props.noSubtitle ? 4 : 'sm'}
      radius="md"
      withBorder
      onClick={() => navigate(`/treatment/${planDefinition.name}`)}
      bg="var(--mantine-color-appColor-0)"
    >
      <Group>
        <ReactSVG src={iconUrl!} style={{ width: 48, height: 48, strokeWidth:0.1, stroke: 'var(--mantine-color-appColor-9)' }} />

        <Stack style={{ width: 'calc(100% - 64px)' }} gap={0}>
          <Title key={'title'} order={5}>
            {planDefinition?.title}
          </Title>
          {!props.noSubtitle && (
            <Text key={'subtitle'} c="gray.6">
              {planDefinition?.subtitle?.slice(0, 72)}
              {Number(planDefinition?.subtitle?.length) > 72 ? '...' : ''}
            </Text>
          )}
        </Stack>
      </Group>
    </Card>
  );
}
