import { loadStripe } from '@stripe/stripe-js';
import { ReactNode } from 'react';
import { StripeContext, stripeContext } from './StripeProvider.context';

export interface StripeProviderProps {
  readonly publishableKey: string;
  readonly children: ReactNode;
}

export function StripeProvider(props: StripeProviderProps): JSX.Element {
  let context: StripeContext = {
    stripePromise: loadStripe(props.publishableKey)
  };

  return <stripeContext.Provider value={context}>{props.children}</stripeContext.Provider>;
}

