import { useParams } from "react-router-dom";
import { PlanDefinitionView } from '@onlinedoc/react';
import { Loading } from "@iehr/react";


export function PlanDefinitionViewPage(): JSX.Element | null {
    const { planDefinitionName } = useParams();
    if(!planDefinitionName  ){
        return <Loading />
    }
    return <PlanDefinitionView planDefinitionName={planDefinitionName} />;
}