import {Stack, Title, Text, TitleOrder, Table } from '@mantine/core';
import { formatDate } from '@iehr/core';
import { QuestionnaireResponse, QuestionnaireResponseItem, QuestionnaireResponseItemAnswer } from '@iehr/fhirtypes';
import { CodeableConceptDisplay, QuantityDisplay, RangeDisplay, ReferenceDisplay } from '@iehr/react';
import { fixQuestionnaireResponse, flattenQuestionnaireResponse } from '../QuestionnaireResponse/questionnaire-utils';

export interface ReviewQuestionnaireResponseProps {
  readonly value: QuestionnaireResponse | undefined;
}

export function ReviewQuestionnaireResponse({ value }: ReviewQuestionnaireResponseProps): JSX.Element {
  if (!value?.item) {
    return <Text>Nothing to show</Text>;
  }

  const items = fixQuestionnaireResponse(flattenQuestionnaireResponse(value.item)).filter((i) => !i.linkId.startsWith('_'));


  return (
    <Stack>
      <Table stickyHeader stickyHeaderOffset={60}>
        <Table.Tbody>
          {items.map((item) => (
            <ItemDisplay key={item.id} item={item} order={6} />
          ))}
        </Table.Tbody>
      </Table>
      <Text>
        <strong>Acknowledgment</strong>
        <br />
        <strong>[✓]</strong> I confirm that the answers I have provided for the above questions are true and accurate to
        the best of my knowledge.
        <br />
        <strong>[✓]</strong> I confirm and agree that any treatment prescribed for me is for my personal use only.
        <br />
        <strong>[✓]</strong> I fully understand the side-effects of the treatment options, their effectiveness and
        alternative options and am happy to continue with my request.
      </Text>
    </Stack>
  );
}

interface ItemDisplayProps {
  item: QuestionnaireResponseItem;
  order: TitleOrder;
}

function ItemDisplay({ item, order }: ItemDisplayProps): JSX.Element {
  const { text: title, answer /*, item: nestedAnswers*/ } = item;

  return answer?.[0] ? (
    <Table.Tr key={item.id}>
      <Table.Td>{title}</Table.Td>
      <Table.Td>
        <AnswerDisplay key={answer[0].id} answer={answer[0]} />
      </Table.Td>
    </Table.Tr>
  ) : (
    <></>
  );
}

interface AnswerDisplayProps {
  answer: QuestionnaireResponseItemAnswer;
}

function AnswerDisplay({ answer }: AnswerDisplayProps): JSX.Element {
  if (!answer) {
    throw new Error('No answer');
  }
  const [[key, value]] = Object.entries(answer);

  switch (key) {
    case 'valueInteger':
      return <Title order={6}>{value}</Title>;
    case 'valueQuantity':
      return <QuantityDisplay value={value} />;
    case 'valueString':
      return <>{(value as string).split('\n').map((v,i)=><Title key={i} order={6}>{v}</Title>)}</>;
    case 'valueCoding':
      return <CodeableConceptDisplay value={{ coding: [value] }} />;
    case 'valueRange':
      return <RangeDisplay value={value} />;
    case 'valueDateTime':
      return <Title order={6}>{formatDate(value)}</Title>;
    case 'valueReference':
      return <ReferenceDisplay value={value} />;
    case 'valueBoolean':
      return <Title order={6}>{value ? 'True' : 'False'}</Title>;
    default:
      return <Title order={6}>{value.toString()}</Title>;
  }
}
