import { Document, useIEHR } from '@iehr/react';
import { Period, PlanDefinition, QuestionnaireResponse, Reference } from '@iehr/fhirtypes';
import { Text, Grid, Paper, Table, Title } from '@mantine/core';
import { normalizeErrorString } from '@iehr/core';
import { useEffect, useState } from 'react';
import { PlanDefinitionInfo } from '../PlanDefinition/PlanDefinitionInfo';
import { useStripePromise } from '../Stripe/StripeProvider.context';

export interface ConfirmQuestionnaireResponseProps {
  readonly resourceId: string;
  readonly searchParams: URLSearchParams;
}

export function ConfirmQuestionnaireResponse({resourceId,searchParams}:ConfirmQuestionnaireResponseProps): JSX.Element | null {
  const iehr = useIEHR();
  const stripePromise= useStripePromise();
  const clientSecret = searchParams.get('payment_intent_client_secret');
  const [message, setMessage] = useState<string>();
  const [planDefinition, setPlanDefinition] = useState<PlanDefinition>();
  const [questionnaireResponse, setQuestionnaireResponse] = useState<QuestionnaireResponse>();
  const period = questionnaireResponse?.extension?.find((qr) => qr.valuePeriod)?.valuePeriod;
  const pharmacy = questionnaireResponse?.item?.find((i) => i.linkId === 'pharmacy-reference')?.answer?.[0]
    .valueReference?.display;

  useEffect(() => {
    if (!questionnaireResponse) return;

    const planDefinitionRef = questionnaireResponse?.extension?.find((qr) =>
      qr.valueReference?.reference?.startsWith('PlanDefinition/')
    )?.valueReference as Reference<PlanDefinition>;

    if (!planDefinitionRef) return;
    iehr
      .readReference<PlanDefinition>(planDefinitionRef)
      .then(setPlanDefinition)
      .catch((err) => {
        console.error(`${planDefinitionRef.reference}: ${normalizeErrorString(err)}`);
      });
  }, [questionnaireResponse]);

  useEffect(() => {
    if (resourceId) {
      iehr
        .readResource('QuestionnaireResponse', resourceId)
        .then(setQuestionnaireResponse)
        .catch((err) => {
          console.error(`QuestionnaireResponse/${resourceId}: ${normalizeErrorString(err)}`);
        });
    }
  }, [resourceId]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      const stripe = await stripePromise;
      if (stripe && clientSecret) {
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

        switch (paymentIntent?.status) {
          case 'succeeded':
            setMessage('Confirmation');
            break;
          case 'processing':
            setMessage('Your payment is processing.');
            break;
          case 'requires_payment_method':
            setMessage('Your payment was not successful, please try again.');
            break;
          default:
            setMessage('Something went wrong.');
            break;
        }
      }
    })();
  }, [clientSecret]);

  if (!questionnaireResponse?.item || !planDefinition) {
    return null;
  }

//  const answers = getQuestionnaireAnswers(questionnaireResponse);

  return (
    <Document bg={'transparent'} bd={'none'} shadow={'none'}>
      <PlanDefinitionInfo planDefinition={planDefinition} />
      <Grid>
        <Grid.Col span={{ base: 12, sm: 4 }}>
          <Paper shadow="xs" m="xs" p="xs">
            <Table stickyHeader stickyHeaderOffset={60}>
              <Table.Tbody>
                {[
                  ['Patient', questionnaireResponse?.subject?.display],
                  ['Date', period?.start?.slice(0, 10)],
                  ['Slot', getPeriod(period)],
                  ['Pharmacy', pharmacy],
                ]
                  .filter((r) => r[1])
                  .map((r) => (
                    <Table.Tr key={r[0]}>
                      <Table.Td>{r[0]}</Table.Td>
                      <Table.Td>{r[1]}</Table.Td>
                    </Table.Tr>
                  ))}
              </Table.Tbody>
            </Table>
          </Paper>
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 'auto' }}>
          <Paper shadow="xs" m="xs" p="xs" maw={600}>
            <Title order={2} mb="md">
              {message}
            </Title>
            <Text> Appointment successfully booked, Please check your email for confirmation mail.</Text>
            <Text> Thank you!</Text>
          </Paper>
        </Grid.Col>
      </Grid>
    </Document>
  );
}

function formatTime(date: Date): string {
  return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
}
function getPeriod(period: Period | undefined): string {
  if (period?.start && period?.end) {
    return `${formatTime(new Date(period.start))}-${formatTime(new Date(period.end))}`;
  }

  if (period?.start) {
    return `${formatTime(new Date(period.start))}`;
  }

  return '';
}
