import { Box, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { formatDateTime, getReferenceString } from '@iehr/core';
import { Patient } from '@iehr/fhirtypes';
import { useIEHR, useIEHRProfile } from '@iehr/react';
import { IconChevronRight } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { InfoButton, InfoSection } from '@onlinedoc/react';

export function Responses(): JSX.Element {
  const iehr = useIEHR();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const profile = useIEHRProfile() as Patient;
  const responses = iehr
    .searchResources('QuestionnaireResponse', `source=${getReferenceString(profile)}&_sort=-authored`)
    .read();

  return (
    <Box p="xl">
      <Title mb="lg">Questionnaire Responses</Title>
      <InfoSection title="Questionnaire Responses">
        <Stack gap={0}>
          {responses.map((resp) => (
            <InfoButton key={resp.id} onClick={() => navigate(`./${resp.id}`)}>
              <div>
                <Text c={theme.primaryColor} fw={500} mb={4}>
                  {resp.extension?.find(e=>e.url.includes('service-name'))?.valueString} - {formatDateTime(resp.authored)}
                </Text>
              </div>
              <IconChevronRight color={theme.colors.gray[5]} />
            </InfoButton>
          ))}
        </Stack>
      </InfoSection>
    </Box>
  );
}
