import { useParams } from "react-router-dom";
import { StripeWidget } from '@onlinedoc/react';
import { Loading } from "@iehr/react";


export function StripePage(): JSX.Element | null {
    const {questionnaireResponseId} = useParams();
    if(!questionnaireResponseId ){
        return <Loading />
    }
    return <StripeWidget questionnaireResponseId={questionnaireResponseId} />;
}