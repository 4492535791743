import { Money, PlanDefinition, Schedule } from '@iehr/fhirtypes';
import { Loading, useIEHR } from '@iehr/react';
import { useEffect, useState } from 'react';
import { ResourceArray, evalFhirPath, normalizeErrorString } from '@iehr/core';
import { getAvailableSlots } from '../Scheduler/utils';
import { Paper, Group, Avatar, Title, Stack, Button, SimpleGrid, Grid, Container } from '@mantine/core';
import Markdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import { PlanDefinitions } from './PlanDefinitions';

export interface PlanDefinitionViewProps {
  readonly planDefinitionName: string;
}

export function PlanDefinitionView({ planDefinitionName }: PlanDefinitionViewProps): JSX.Element | null {
  const navigate = useNavigate();
  const iehr = useIEHR();
  const [planDefinition, setPlanDefinition] = useState<PlanDefinition>();
  const [schedules, setSchedules] = useState<ResourceArray<Schedule>>();
  const [iconUrl, setIconUrl] = useState<string>();
  const [price, setPrice] = useState<Money>();
  const [date, setDate] = useState<Date>();
  const [day, setDay] = useState<string>();
  const [tags, setTags] = useState<string[]>();

  

  useEffect(() => {
    if (planDefinitionName) {
      iehr
        .searchOne('PlanDefinition', `name=${planDefinitionName}`)
        .then(setPlanDefinition)
        .catch((err) => {
          console.error(`PlanDefinition/${planDefinitionName}: ${normalizeErrorString(err)}`);
        });
    }
  }, [planDefinitionName]);

  useEffect(() => {
    if (!planDefinition) {
      return;
    }
    if(!planDefinition.description){
      navigate(`/schedule/${planDefinitionName}`);
    }
    setIconUrl(planDefinition.relatedArtifact?.find((ra) => ra.label === 'icon')?.document?.url);
    setPrice(planDefinition.extension?.find((ra) => ra.valueMoney)?.valueMoney);
    setTags(
      evalFhirPath(
        "PlanDefinition.topic.coding.where( system = 'http://onlinedoc.ie/fhir/CodeSystem/service-tag').code",
        planDefinition
      ) as string[]
    );

    const startTime = new Date();
    startTime.setHours(0, 0, 0, 0);

    const endTime = new Date(startTime.getTime());
    endTime.setDate(endTime.getDate() + 7); //only 1 week ahead
    endTime.setTime(endTime.getTime() - 1); //end of day

    const category = evalFhirPath(
      "PlanDefinition.topic.coding.where( system = 'http://onlinedoc.ie/fhir/CodeSystem/service-category').code",
      planDefinition
    )[0];
    iehr
      .searchResources(
        'Schedule',
        `active=true&service-category=${category}&date=ge${startTime.toISOString()}&date=le${endTime.toISOString()}`
      )
      .then((schedules) => {
        setSchedules(schedules);
      })
      .catch((err) => {
        throw err;
      });
  }, [iehr, planDefinition]);

  //get all the available slots for the date
  useEffect(() => {
    (async () => {
      if (!iehr || !schedules || !planDefinition) {
        return;
      }
      const slots = await getAvailableSlots(iehr, schedules, planDefinition);
      if (slots.length) {
        const date = new Date(slots[0].start);
        setDate(date);

        const today = new Date();
        const tomorrow = new Date(new Date());
        tomorrow.setDate(today.getDate() + 1);

        if (date.toISOString().slice(0, 10) === today.toISOString().slice(0, 10)) {
          setDay('Today');
        } else if (date.toISOString().slice(0, 10) === tomorrow.toISOString().slice(0, 10)) {
          setDay('Tomorrow');
        } else {
          setDay(date.toISOString().slice(5, 10).replace('-', '/'));
        }
      }
    })();
  }, [iehr, schedules, planDefinition]);

  if (!planDefinition) {
    return <Loading />;
  }

  return (
    <Container>
      <Grid>
        <Grid.Col span={{ base: 12, sm: 8 }}>
          <Paper shadow="xs" m="xs" p="xs">
            <SimpleGrid cols={{ base: 1, xs: 2 }}>
              <Group>
                <Avatar src={iconUrl} size="xl" radius={5} />
                <Title order={2}>{planDefinition.title}</Title>
              </Group>
              {date && (
                <Group justify="right">
                  <Stack gap={0}>
                    Available - {day} at {date.toISOString().slice(11, 16)}
                    {price && (
                      <Button onClick={() => navigate(`/schedule/${planDefinitionName}`)}>
                        Book Now - €{price.value}
                      </Button>
                    )}
                  </Stack>
                </Group>
              )}
            </SimpleGrid>

            <Markdown className="mb-6 text-lg text-gray-600">{planDefinition.subtitle}</Markdown>
            <Title order={4}>Information</Title>
            <Markdown className="mb-6 text-lg text-gray-600">{planDefinition.description}</Markdown>
            {date && (
              <Group justify="center">
                <Stack gap={0}>
                  Available - {day} at {date.toISOString().slice(11, 16)}
                  {price && (
                    <Button onClick={() => navigate(`/schedule/${planDefinitionName}`)}>Book Now - €{price.value}</Button>
                  )}
                </Stack>
              </Group>
            )}
          </Paper>
        </Grid.Col>
        {tags && (
          <Grid.Col span={{ base: 12, sm: 4 }}>
            <PlanDefinitions noHeader noCol noSubtitle category={tags.join(',')} />
          </Grid.Col>
        )}
      </Grid>
    </Container>
  );
}
