import { Box } from '@mantine/core';
import { DiagnosticReport } from '@iehr/fhirtypes';
import { DiagnosticReportDisplay, useIEHR } from '@iehr/react';
import { useParams } from 'react-router-dom';
import { InfoSection } from '@onlinedoc/react';

export function LabResult(): JSX.Element {
  const iehr = useIEHR();
  const { resultId = '' } = useParams();
  const resource: DiagnosticReport = iehr.readResource('DiagnosticReport', resultId).read();

  return (
    <Box p="xl">
      <InfoSection>
        <DiagnosticReportDisplay value={resource} />
      </InfoSection>
    </Box>
  );
}
