import { Patient, Period, PlanDefinition, QuestionnaireResponse, Reference } from '@iehr/fhirtypes';
import { Document, Loading, useIEHR, useIEHRNavigate } from '@iehr/react';
import { StripeElements, StripeOptions } from './StripeElements';
import {
  calculateAgeString,
  evalFhirPath,
  formatHumanName,
  getReferenceString,
  normalizeErrorString,
} from '@iehr/core';
import { useState, useEffect } from 'react';
import { Grid, Paper, Table, Title } from '@mantine/core';
import { PlanDefinitionInfo } from '../PlanDefinition/PlanDefinitionInfo';
import { flattenFixQuestionnaireResponse } from '../QuestionnaireResponse/questionnaire-utils';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export interface StripeWidgetProps {
  readonly questionnaireResponseId: string;
}

export function StripeWidget({ questionnaireResponseId }: StripeWidgetProps): JSX.Element | null {
  const iehr = useIEHR();
  const navigate = useIEHRNavigate();
  const [questionnaireResponse, setQuestionnaireResponse] = useState<QuestionnaireResponse>();
  const [planDefinition, setPlanDefinition] = useState<PlanDefinition>();
  const [stripeOptions, setStripeOptions] = useState<StripeOptions>();
  const [paid, setPaid] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [period, setPeriod] = useState<Period>();
  const [patient, setPatient] = useState<Patient>();
  const [pharmacy, setPharmacy] = useState<string>();

  useEffect(() => {
    if (questionnaireResponseId) {
      iehr
        .readResource('QuestionnaireResponse', questionnaireResponseId)
        .then(setQuestionnaireResponse)
        .catch((err) => {
          console.error(`QuestionnaireResponse/${questionnaireResponseId}: ${normalizeErrorString(err)}`);
          setErrorMessage(`QuestionnaireResponse/${questionnaireResponseId}: ${normalizeErrorString(err)}`);
        });
    }
  }, [questionnaireResponseId]);

  useEffect(() => {
    if (!questionnaireResponse) return;
    setPeriod(questionnaireResponse?.extension?.find((qr) => qr.valuePeriod)?.valuePeriod);

    if (questionnaireResponse.item) {
      const ff = flattenFixQuestionnaireResponse(questionnaireResponse);

      const patientStr = ff.find((r) => r.linkId === '_patient-reference')?.answer?.[0]?.valueString;
      if (patientStr?.length) {
        const patient = JSON.parse(patientStr);
        setPatient(patient);
      }
      let pharmacy = ff.find((r) => r.linkId === '_pharmacy-reference')?.answer?.[0]?.valueReference?.display;
      if (!pharmacy) {
        pharmacy = ff
          ?.find((r) => r.linkId === '_pharmacy')
          ?.answer?.[0]?.valueString?.split('\n')
          .join(', ');
      }
      setPharmacy(pharmacy);
    }

    const planDefinitionRef = questionnaireResponse?.extension?.find((qr) =>
      qr.valueReference?.reference?.startsWith('PlanDefinition/')
    )?.valueReference as Reference<PlanDefinition>;

    if (!planDefinitionRef) return;
    iehr
      .readReference<PlanDefinition>(planDefinitionRef)
      .then(setPlanDefinition)
      .catch((err) => {
        console.error(`${planDefinitionRef.reference}: ${normalizeErrorString(err)}`);
      });
  }, [questionnaireResponse]);

  // set stripe customer info
  useEffect(() => {
    if (!questionnaireResponse) {
      return;
    }

    const paid = questionnaireResponse.extension?.some((qr) => qr.url === 'http://stripe.com/paymentintent');
    setPaid(paid);

    if (paid) {
      return;
    }

    setPaid(questionnaireResponse.extension?.some((qr) => qr.url === 'http://stripe.com/paymentintent'));

    const price = questionnaireResponse.extension?.find((qr) => qr.valueMoney)?.valueMoney;
    const contact = questionnaireResponse.extension?.find((qr) => qr.valueContactDetail)?.valueContactDetail;

    if (price && price.currency) {
      setStripeOptions({
        intent: {
          amount: (price.value as number) * 100,
          currency: price.currency.toLowerCase(),
          description: 'Medical Service',
          customer: contact?.id as string,
          metadata: {
            resource_reference: getReferenceString(questionnaireResponse),
          },
        },
        customer: {
          name: contact?.name as string,
          email: contact?.telecom?.[0].value as string,
        },
      });
      // for test
      // navigate(`/health/confirmation/${getReferenceString(createdResponse)}`);
    }
  }, [questionnaireResponse]);

  if (errorMessage) {
    return (
      <Title c="red" order={3}>
        {errorMessage}
      </Title>
    );
  }

  if (questionnaireResponse && paid) {
    navigate(`/health/confirmation/${getReferenceString(questionnaireResponse)}`);
  }

  if (!stripeOptions || !planDefinition) {
    return <Loading />;
  }

  return (
    <Document bg={'transparent'} bd={'none'} shadow={'none'}>
      <PlanDefinitionInfo planDefinition={planDefinition} />
      <Grid>
        <Grid.Col span={{ base: 12, sm: 4 }}>
          <Paper shadow="xs" m="xs" p="xs">
            <Table stickyHeader stickyHeaderOffset={60}>
              <Table.Tbody>
                {[
                  ['Date', period?.start?.slice(0, 10)],
                  ['Slot', getPeriod(period)],
                  [
                    'Patient',
                    patient?.name?.[0]
                      ? formatHumanName(patient?.name?.[0]!) +
                        (patient?.birthDate ? ` (${calculateAgeString(patient?.birthDate)})` : '')
                      : undefined,
                  ],
                  ['Email', evalFhirPath("telecom.where(system='email').value", patient ?? {})[0]],
                  [
                    'Phone',
                    patient?.telecom
                      ? phoneUtil.formatOutOfCountryCallingNumber(
                          phoneUtil.parseAndKeepRawInput(
                            evalFhirPath("telecom.where(system='phone').value", patient)[0] as string
                          )
                        )
                      : undefined,
                  ],
                  ['Address', evalFhirPath('address.first().text', patient ?? {})[0]],
                  ['Pharmacy', pharmacy],
                ]
                  .filter((r) => r[1])
                  .map((r) => (
                    <Table.Tr key={r[0] as string}>
                      <Table.Td>{r[0] as string}</Table.Td>
                      <Table.Td>{r[1] as string}</Table.Td>
                    </Table.Tr>
                  ))}
              </Table.Tbody>
            </Table>
          </Paper>
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 'auto' }}>
          <Paper shadow="xs" m="xs" p="xs" maw={600}>
            <StripeElements
              mode={'payment'}
              return_url={`${window.location.origin}/schedule/confirmation/${stripeOptions.intent?.metadata?.resource_reference}`}
              stripeOptions={stripeOptions}
            />
          </Paper>
        </Grid.Col>
      </Grid>
    </Document>
  );
}
function formatTime(date: Date): string {
  return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
}
function getPeriod(period: Period | undefined): string {
  if (period?.start && period?.end) {
    return `${formatTime(new Date(period.start))}-${formatTime(new Date(period.end))}`;
  }

  if (period?.start) {
    return `${formatTime(new Date(period.start))}`;
  }

  return '';
}
