import { useParams } from "react-router-dom";
import { ScheduleView } from '@onlinedoc/react';
import { Loading } from "@iehr/react";


export function SchedulePage(): JSX.Element | null {
    const { planDefinitionName, questionnaireResponseId } = useParams();
    if(!planDefinitionName  ){
        return <Loading />
    }
    return <ScheduleView planDefinitionName={planDefinitionName} questionnaireResponseId={questionnaireResponseId!}  />;
}