import { Box, Title } from '@mantine/core';
import { Immunization } from '@iehr/fhirtypes';
import { ResourceTable, useIEHR } from '@iehr/react';
import { useParams } from 'react-router-dom';
import { InfoSection } from '@onlinedoc/react';

export function Vaccine(): JSX.Element {
  const iehr = useIEHR();
  const { vaccineId = '' } = useParams();
  const vaccine: Immunization = iehr.readResource('Immunization', vaccineId).read();

  return (
    <Box p="xl">
      <Title order={2} mb="md">
        {vaccine.vaccineCode?.text}
      </Title>
      <InfoSection title="Vaccine">
        <ResourceTable value={vaccine} ignoreMissingValues />
      </InfoSection>
    </Box>
  );
}
