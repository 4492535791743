import { Box, Title } from '@mantine/core';
import { CarePlan } from '@iehr/fhirtypes';
import { ResourceTable, useIEHR } from '@iehr/react';
import { useParams } from 'react-router-dom';
import { InfoSection } from '../InfoSection/InfoSection';

export function ActionItem(): JSX.Element {
  const iehr = useIEHR();
  const { itemId } = useParams();
  const resource: CarePlan = iehr.readResource('CarePlan', itemId as string).read();

  return (
    <Box p="xl">
      <Title order={2} mb="md">
        {resource.title}
      </Title>
      <InfoSection title="Action Item">
        <ResourceTable value={resource} ignoreMissingValues />
      </InfoSection>
    </Box>
  );
}
