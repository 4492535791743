export interface OnlineDocAppConfig {
  baseUrl?: string;
  clientId?: string;
  clientSecret?: string;
  projectId?: string;
  googleClientId?: string;
  googleApiKey?: string;
  recaptchaSiteKey?: string;
  registerEnabled?: boolean | string;
  stripePublishableKey?: string;
  color: string;
  shade: number;
}

const config: OnlineDocAppConfig = {
  baseUrl: import.meta.env?.IEHR_BASE_URL,
  clientId: import.meta.env?.IEHR_CLIENT_ID,
  clientSecret: import.meta.env?.IEHR_CLIENT_SECRET,
  projectId: import.meta.env?.IEHR_PROJECT_ID,
  googleClientId: import.meta.env?.GOOGLE_CLIENT_ID,
  googleApiKey: import.meta.env?.GOOGLE_API_KEY,
  recaptchaSiteKey: import.meta.env?.RECAPTCHA_SITE_KEY,
  registerEnabled: import.meta.env?.IEHR_REGISTER_ENABLED,
  stripePublishableKey: import.meta.env?.IEHR_STRIPE_PUBLISHABLE_KEY,
  color: import.meta.env?.IEHR_APP_COLOR,
  shade: Number(import.meta.env?.IEHR_APP_SHADE),
};

export function getConfig(): OnlineDocAppConfig {
  if(!config.color) config.color='#828282';
  if(!config.shade) config.shade=5;
  return config;
}

export function isRegisterEnabled(): boolean {
  // This try/catch exists to prevent Rollup optimization from removing this function
  try {
    return config.registerEnabled !== false && config.registerEnabled !== 'false';
  } catch {
    return true;
  }
}
