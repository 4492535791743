import { BackgroundImage, Box, SimpleGrid, Title } from '@mantine/core';
import { SignInForm } from '@iehr/react';
import { useNavigate } from 'react-router-dom';
import { getConfig } from '../config';
import { Logo } from '@onlinedoc/react';

export function SignInPage(): JSX.Element {
  const navigate = useNavigate();
  const config = getConfig();

  return (
    <BackgroundImage
      h="calc( 100dvh + 64px)"
      pt={100}
      pb={200}
      top={-64}
      pos="absolute"
      src="https://www.onlinedoc.ie/assets/images/office-about-1.jpg"
    >
      <SignInForm projectId={config.projectId} googleClientId={config.googleClientId} onSuccess={() => navigate('/')}>
        <Logo size={96} />
        <h2>Welcome to the Patient Portal</h2>
      </SignInForm>
    </BackgroundImage>
  );
}
