import { Anchor, Avatar, Group, Modal, Paper, Stack, Text, Title } from '@mantine/core';
import { PlanDefinition } from '@iehr/fhirtypes';
import { useState } from 'react';
import Markdown from 'react-markdown';

export interface PlanDefinitionInfoProps {
  readonly planDefinition: PlanDefinition;
}
export function PlanDefinitionInfo({ planDefinition }: PlanDefinitionInfoProps): JSX.Element | null {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const iconUrl = planDefinition.relatedArtifact?.find((ra) => ra.label === 'icon')?.document?.url;

  return (
    <Paper shadow="xs" m="xs" p="xs">
      <Group>
        <Avatar src={iconUrl} size="xl" radius={5} />
        <Title order={2}>{planDefinition.title}</Title>
      </Group>
      
      <Markdown className="mb-6 text-lg text-gray-600">{planDefinition.subtitle}</Markdown>
      <Anchor onClick={() => setModalOpen(true)}> More Info...</Anchor>
      <RenewalModal prev={planDefinition} opened={modalOpen} setOpened={setModalOpen} />
    </Paper>
  );
}

function RenewalModal({
  prev,
  opened,
  setOpened,
}: {
  readonly prev: PlanDefinition;
  readonly opened: boolean;
  readonly setOpened: (o: boolean) => void;
}): JSX.Element {
  return (
    <Modal
      size="lg"
      opened={opened}
      onClose={() => setOpened(false)}
      title={<Text size="xl">{prev.name}</Text>}
    >
      <Stack gap="md">
        <KeyValue name="Category" value={prev.topic?.map(t=>t.coding?.map(c=>c.code).join(',')).join(',')} />
        <KeyValue name="Description" value={prev.subtitle} />
        <KeyValue name="Information" value={prev.description} />
      </Stack>
    </Modal>
  );
}

function KeyValue({ name, value }: { name: string; value: string | undefined }): JSX.Element {
  return (
    <div>
      <Text size="xs" color="gray" tt="uppercase">
        {name}
      </Text>
      <Markdown >
        {value}
      </Markdown>
    </div>
  );
}
