import { Stripe } from '@stripe/stripe-js';
import { createContext, useContext } from 'react';

export const stripeContext = createContext(undefined as StripeContext | undefined);

export interface StripeContext {
  stripePromise: Promise<Stripe | null>;
}

/**
 * Returns the StripeContext instance.
 * @returns The StripeContext instance.
 */
export function useStripeContext(): StripeContext {
  return useContext(stripeContext) as StripeContext;
}

/**
 * Returns the useStripeContext instance.
 * This is a shortcut for useStripeContext().stripePromise.
 * @returns The IEHRClient instance.
 */
export function useStripePromise(): Promise<Stripe | null> {
  return useStripeContext().stripePromise;
}

