import {
  createReference,
  evalFhirPath,
  formatHumanName,
  getQuestionnaireAnswers,
  getReferenceString,
  IEHRClient,
  isReference,
} from '@iehr/core';
import { Patient, PlanDefinition, QuestionnaireResponse, Reference, Schedule, Slot } from '@iehr/fhirtypes';

export async function getAvailableSlots(
  iehr: IEHRClient,
  schedules: Schedule[],
  planDefinition: PlanDefinition
): Promise<Slot[]> {
  const slots = [];
  for (const schedule of schedules) {
    const currentSlots = await getAvailableScheduleSlots(iehr, schedule, planDefinition);

    slots.push(...currentSlots);
  }
  return (
    slots
      //remove duplicates
      .filter((slot1, i, arr) => arr.findIndex((slot2) => slot2.start === slot1.start) === i)
      //sort based on start time
      .sort((a, b) => a.start.localeCompare(b.start))
  );
}

export async function getAvailableScheduleSlots(
  iehr: IEHRClient,
  schedule: Schedule,
  planDefintion: PlanDefinition
): Promise<Slot[]> {
  if (!schedule.planningHorizon || !schedule.planningHorizon.start || !schedule.planningHorizon.end) {
    console.error('Schedule has no planningHorizon:', schedule.id);
    return [];
  }
  const durationString = evalFhirPath('PlanDefinition.extension.valueDuration.value', planDefintion);
  if (!durationString) {
    console.error('PlanDefinition has no duration:', planDefintion.id);
    return [];
  }

  const busySlots = await iehr.searchResources(
    'Slot',
    new URLSearchParams([
      ['schedule', isReference(schedule) ? (schedule.reference as string) : getReferenceString(schedule)],
      ['status', 'busy'],
    ])
  );

  const duration = Number(durationString) * 60000; //60 * 1000
  const scheduleStart = new Date(schedule.planningHorizon.start).getTime();
  const scheduleEnd = new Date(schedule.planningHorizon.end).getTime();

  const scheduleRef = createReference(schedule);
  const slots: Slot[] = [];

  const now = new Date().getTime();
  for (let interval = scheduleStart; interval < scheduleEnd; interval += duration) {
    if (interval < now) {
      continue;
    }

    const start = new Date(interval);
    const end = new Date(interval + duration);

    //is this a busy slot?
    if (
      busySlots.find(
        (s) =>
          (start.getTime() >= new Date(s.start).getTime() && start.getTime() < new Date(s.end).getTime()) ||
          (end.getTime() >= new Date(s.start).getTime() && end.getTime() < new Date(s.end).getTime())
      )
    ) {
      continue;
    }

    const slot: Slot = {
      resourceType: 'Slot',
      status: 'free',
      start: start.toISOString(),
      end: end.toISOString(),
      schedule: scheduleRef,
    };
    slots.push(slot);
  }

  return slots;
}

export interface CustomerInfo {
  patientRef?: Reference<Patient>;
  name?: string;
  email?: string;
  stripeCustomerId?: string;
}

export async function getCustomerInfo(iehr: IEHRClient, response: QuestionnaireResponse): Promise<CustomerInfo> {
  const customerInfo: CustomerInfo = {};

  const profile = iehr.getProfile();

  const answers = getQuestionnaireAnswers(response);
  //first check for patient reference field
  if (answers['patient-reference']) {
    // Add Patient as subject
    const patient = await iehr.readReference(answers['patient-reference'].valueReference as Reference<Patient>);
    customerInfo.patientRef = answers['patient-reference'].valueReference as Reference<Patient>;

    const identifier = patient.identifier?.find((id) => id.system === 'https://stripe.com/customer/id');
    if (identifier?.value) {
      customerInfo.stripeCustomerId = identifier?.value;
    } else {
      if (patient.name?.[0]) {
        customerInfo.name = formatHumanName(patient.name[0]);
      }
      const contactPoint = patient.telecom?.find((cp) => cp.system === 'email');
      if (contactPoint?.value) {
        customerInfo.email = contactPoint?.value;
      }
    }
  }
  //second check for patient information
  else if (answers['patient_first_name']?.valueString) {
    customerInfo.name = answers['patient_first_name'].valueString + ' ' + answers['patient_last_name']?.valueString;
    if (answers['patient_email']?.valueString) {
      customerInfo.email = answers['patient_email'].valueString;
    }
  }
  //just use the logged in user
  else if (profile) {
    response.subject = createReference(profile);
    const identifier = profile.identifier?.find((id) => id.system === 'https://stripe.com/customer/id');
    if (identifier?.value) {
      customerInfo.stripeCustomerId = identifier?.value;
    } else {
      if (profile.name?.[0]) {
        customerInfo.name = formatHumanName(profile.name[0]);
      }
      const contactPoint = profile.telecom?.find((cp) => cp.system === 'email');
      if (contactPoint?.value) {
        customerInfo.email = contactPoint?.value;
      }
    }
  }

  return customerInfo;
}
