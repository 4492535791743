import { Title } from '@mantine/core';
import { Document, ResourceTable, useIEHR } from '@iehr/react';
import { useParams } from 'react-router-dom';

export function ObservationPage(): JSX.Element {
  const iehr = useIEHR();
  const { observationId = '' } = useParams();
  const resource = iehr.readResource('Observation', observationId).read();

  return (
    <Document>
      <Title>Observation</Title>
      <ResourceTable value={resource} ignoreMissingValues />
    </Document>
  );
}
