import { useIEHR } from '@iehr/react';
import { useEffect } from 'react';

export function SignOutPage(): null {
  const iehr = useIEHR();

  useEffect(() => {
    iehr
      .signOut()
      .then(() => {
        window.location.href = '/';
      })
      .catch(console.error);
  }, [iehr]);

  return null;
}
