import { useState, useEffect } from 'react';
import { ComboboxItem, Input, NativeSelect, SimpleGrid } from '@mantine/core';

export interface QuestionnaireFormDateItemProps {
  readonly label?: string;
  readonly defaultValue?: string;
  readonly required?: boolean;
  readonly onChange: (patient: string | undefined) => void;
}

export function DateInput({ label, defaultValue, required, onChange }: QuestionnaireFormDateItemProps): JSX.Element {
  const [year, setYear] = useState<number|undefined>(defaultValue ? new Date(defaultValue).getFullYear() : undefined);
  const [month, setMonth] = useState<number|undefined>(defaultValue ? new Date(defaultValue).getMonth() : undefined);
  const [day, setDay] = useState<number|undefined>(defaultValue ? new Date(defaultValue).getDate()+1 : undefined);
  const [date, setDate] = useState<Date>();

  const thisYear = new Date().getFullYear();

  const years = [
    { label: 'Year', value: '' },
    ...Array.from({ length: 105 }, (v, k) => k ).map((y) => `${thisYear - y}`),
  ];
  const months: ComboboxItem[] = [
    { label: 'Month', value: '' },
    { label: 'Jan', value: '0' },
    { label: 'Feb', value: '1' },
    { label: 'Mar', value: '2' },
    { label: 'Apr', value: '3' },
    { label: 'May', value: '4' },
    { label: 'Jun', value: '5' },
    { label: 'Jul', value: '6' },
    { label: 'Aug', value: '7' },
    { label: 'Sep', value: '8' },
    { label: 'Oct', value: '9' },
    { label: 'Nov', value: '10' },
    { label: 'Dec', value: '11' },
  ];
  const days = [{ label: 'Day', value: '' }, ...Array.from({ length: 31 }, (v, k) => k).map((d) => `${d + 1}`)];

  useEffect(() => {
    if (year && !isNaN(month as number) && day) {
      setDate(new Date(year, month as number, day));
    } else {
      setDate(undefined);
    }
  }, [year, month, day]);

  useEffect(() => {
    onChange(date ? date.toISOString().slice(0, 10) : undefined);
  }, [date]);

  return (
    <Input.Wrapper label={label} required={!!required} error={!year}>
      <SimpleGrid cols={3}>
        <NativeSelect
          error={!!required && !year}
          withErrorStyles={false}
          required={!!required}
          data={years}
          defaultValue={year}
          onChange={(e) => setYear(Number(e.currentTarget.value))}
        />
        <NativeSelect
          error={!!required && (isNaN(month!) || (month as number)<0) }
          withErrorStyles={false}
          required={!!required}
          data={months}
          defaultValue={month}
          onChange={(e) => setMonth(Number(e.currentTarget.value))}
        />
        <NativeSelect
          error={!!required && !day}
          withErrorStyles={false}
          required={!!required}
          data={days}
          defaultValue={day}
          onChange={(e) => setDay(Number(e.currentTarget.value))}
        />
      </SimpleGrid>
    </Input.Wrapper>
  );
}
