import { useState, useEffect, useCallback } from 'react';
import { NativeSelect, Stack } from '@mantine/core';
import { Loading, useIEHRProfile } from '@iehr/react';
import { Patient, QuestionnaireItem, QuestionnaireResponseItemAnswer } from '@iehr/fhirtypes';
import { PatientInput } from './PatientInput';
import { deepEquals, evalFhirPath, TypedValue } from '@iehr/core';

export function patientReferenceFunc(
  item: QuestionnaireItem,
  defaultValue: TypedValue,
  onChangeAnswer: (newResponseAnswer: QuestionnaireResponseItemAnswer | QuestionnaireResponseItemAnswer[]) => void
) {
  return (
    <PatientReference
      defaultValue={defaultValue?.value}
      onPatientSelect={(patient) => {
        console.log('onPatientSelect', patient);
        onChangeAnswer(
          !patient
            ? {}
            : {
                valueString: patient,
              }
        );
      }}
    />
  );
}

export interface PatientReferenceProps {
  readonly defaultValue?: string;
  readonly onPatientSelect: (patient: string | undefined) => void;
}

export function PatientReference({ defaultValue, onPatientSelect }: PatientReferenceProps): JSX.Element {
  const profile = useIEHRProfile();

  const [patients, setPatients] = useState<Record<string, Patient >>({});
  const [profilePatient, setProfilePatient] = useState<Patient>();
  const [_patient, setPatient] = useState<Patient | unknown>(defaultValue?JSON.parse(defaultValue):undefined);
  const [options, setOptions] = useState<any>();
  const [option, setOption] = useState<string>();

  useEffect(() => {
    if (profile && ['Patient'].includes(profile.resourceType)) {
      setProfilePatient(profile as Patient);
    }
  }, [profile]);

  useEffect(() => {
    const _patient:any = {};
    if (defaultValue) {
      const p = JSON.parse(defaultValue) as Patient;
      const fullName = evalFhirPath("name.select(given.first() +' '+family)", p)[0] as string;
      if (fullName) {
        _patient[fullName] = p;
        setOption(' ')
        setOption(fullName)
      }
    }


  

    if (profilePatient) {
      const fullName = evalFhirPath("name.select(given.first() +' '+family)", profilePatient)[0] as string;
      _patient["I'm the Patient"]=undefined as unknown as Patient;
      if (!_patient[fullName] && fullName) {
        _patient[fullName] = profilePatient;
        _patient["I'm related to the Patient"] = {
          resourceType: 'Patient',
          active: true,
          contact: [
            {
              name: profilePatient.name?.[0],
              telecom: profilePatient.telecom,
              address: profilePatient.address?.[0],
            },
          ],
        };
      }        
    }else{
      _patient["I'm the Patient"]={
        resourceType: 'Patient',
        active: true
      };
      _patient["I'm related to the Patient"] = {
        resourceType: 'Patient',
        active: true,
        contact: [],
      };
    }


    const data = [{ label: ' ', value: '' }, ...Object.entries(_patient).filter(([key,val])=>val).map(([key,val])=>key) ]
    const options = [... new Set(data)]
    setOptions(options);
    setPatients(_patient);

  }, [profilePatient]);

  useEffect(() => {
    if (option && patients?.[option]) {
      handlePatientChange(patients[option]);
    }
  }, [option]);

  const handlePatientChange = useCallback((newPatient: Patient | undefined) => {
    setPatient((prePatient: Patient | undefined) => {
      if (newPatient && !newPatient?.name) {
        return;
      }
      if (newPatient && deepEquals(newPatient,prePatient )) {
        return;
      }
      onPatientSelect(newPatient ? JSON.stringify(newPatient) : undefined);
      return newPatient;
    });

    //}
  }, []);

  if (!patients || !options) {
    return <Loading />;
  }
  return (
    <Stack>
      {patients && options && (
        <NativeSelect
          required
          defaultValue={option}
          data={options}
          onChange={(e) => setOption(e.currentTarget.value)}
        />
      )}

      {option && <PatientInput key={option} defaultValue={patients[option]!} onChange={handlePatientChange} />}
    </Stack>
  );
}
