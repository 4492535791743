import { AppShell, ErrorBoundary, useIEHR, useIEHRProfile } from '@iehr/react';
import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Loading } from './components/Loading';
import { RegisterPage } from './pages/RegisterPage';
import { SignInPage } from './pages/SignInPage';
import {
  IconGenderMale,
  IconGenderFemme,
  IconEye,
  IconWheelchair,
  IconFriends,
  IconVaccine,
  IconHandStop,
  IconTestPipe,
  IconPrescription,
  IconQuestionMark,
  IconHeartbeat,
  IconChecklist,
  IconCalendarClock,
} from '@tabler/icons-react';
import { LabResults } from './pages/health-record/LabResults';
import { Medications } from './pages/health-record/Medications';
import { Responses } from './pages/health-record/Responses';
import { Response } from './pages/health-record/Response';
import { Vaccines } from './pages/health-record/Vaccines';
import { Vitals } from './pages/health-record/Vitals';
import { HomePage } from './pages/HomePage';
import { MessageTable } from './pages/MessageTablePage';
import { Messages } from './pages/MessagesPage';
import { ObservationPage } from './pages/ObservationPage';
import { QuestionnairePage } from './pages/QuestionnairePage';
import { SignOutPage } from './pages/SignOutPage';
import { AccountPage } from './pages/account';
import { MembershipAndBilling } from './pages/account/MembershipAndBilling';
import { Profile } from './pages/account/Profile';
import { Provider } from './pages/account/Provider';
import { LabResult } from './pages/health-record/LabResult';
import { Measurement } from './pages/health-record/Measurement';
import { Medication } from './pages/health-record/Medication';
import { Vaccine } from './pages/health-record/Vaccine';
import { version } from '../package.json';
import { ActionItems, ActionItem, Logo } from '@onlinedoc/react';
import { ConfirmQuestionnaireResponsePage } from './pages/lib/ConfirmQuestionnaireResponsePage';
import { PlanDefinitionViewPage } from './pages/lib/PlanDefinitionViewPage';
import { PlanDefinitionsPage } from './pages/lib/PlanDefinitionsPage';
import { StripePage } from './pages/lib/StripePage';
import { getConfig } from './config';
import { useMantineColorScheme } from '@mantine/core';
import { SchedulePage } from './pages/lib/SchedulePage';

export function App(): JSX.Element | null {
  const config = getConfig();
  const iehr = useIEHR();
  const profile = useIEHRProfile();
  const { colorScheme } = useMantineColorScheme();
  const dark = colorScheme === 'dark';

  if (iehr.isLoading()) {
    return null;
  }

  return (
    <AppShell
      logo={<Logo size={64} fillTitle={dark ? 'white' : '#043b73'} show='title'/>}
      headerSearchDisabled={true}
      resourceTypeSearchDisabled={true}
      version={version}
      menus={
        !profile
          ? []
          : [
              {
                title: 'Get Care',
                links: [
                  //{ icon: <IconUser />, label: 'In Person', href: '/health/in-person' },
                  { icon: <IconCalendarClock />, label: 'Appointment', href: '/health/appointment' },
                  //{ icon: <IconWifiOff />, label: 'Offline', href: '/health/offline'},
                  { icon: <IconGenderFemme />, label: "Women's Health", href: '/health/female' },
                  { icon: <IconGenderMale />, label: "Men's Health", href: '/health/male' },
                  { icon: <IconWheelchair />, label: 'General Health', href: '/health/general,lung' },
                  { icon: <IconFriends />, label: 'Sexual Health', href: '/health/sexual' },
                  { icon: <IconHandStop />, label: 'Skin and Hair', href: '/health/skin,hair' },
                  { icon: <IconEye />, label: 'Eye, Ear, Nose, Throat', href: '/health/eye,ear,nose,throat,mouth' },
                  { icon: <IconVaccine />, label: 'Vaccination', href: '/health/vaccination' },
                ],
              },
              {
                title: 'Health Record',
                links: [
                  { icon: <IconTestPipe />, label: 'Lab Results', href: '/health-record/lab-results' },
                  { icon: <IconPrescription />, label: 'Medications', href: '/health-record/medications' },
                  {
                    icon: <IconQuestionMark />,
                    label: 'Questionnaire Responses',
                    href: '/health-record/questionnaire-responses',
                  },
                  { icon: <IconVaccine />, label: 'Vaccines', href: '/health-record/vaccines' },
                  {
                    icon: <IconHeartbeat />,
                    label: 'Vitals',
                    href: '/health-record/vitals',
                    /*
                    subMenu: Object.values(measurementsMeta).map(({ title, id }) => ({
                      name: title,
                      href: `/health-record/vitals/${id}`,
                    }))
                      */
                  },
                ],
              },
              {
                title: 'Care Plan',
                links: [{ icon: <IconChecklist />, label: 'Action Items', href: '/care-plan/action-items' }],
              },
            ]
      }
      /*
      notifications={
        profile && (
          <>
            <NotificationIcon
              label="Mail"
              resourceType="Communication"
              countCriteria={`recipient=${getReferenceString(
                profile as ProfileResource
              )}&status:not=completed&_summary=count`}
              subscriptionCriteria={`Communication?recipient=${getReferenceString(profile as ProfileResource)}`}
              iconComponent={<IconMail />}
              onClick={() =>
                navigate(
                  `/Communication?recipient=${getReferenceString(
                    profile as ProfileResource
                  )}&status:not=completed&_fields=sender,recipient,subject,status,_lastUpdated`
                )
              }
            />
            <NotificationIcon
              label="Tasks"
              resourceType="Task"
              countCriteria={`owner=${getReferenceString(
                profile as ProfileResource
              )}&status:not=completed&_summary=count`}
              subscriptionCriteria={`Task?owner=${getReferenceString(profile as ProfileResource)}`}
              iconComponent={<IconClipboardCheck />}
              onClick={() =>
                navigate(
                  `/Task?owner=${getReferenceString(
                    profile as ProfileResource
                  )}&status:not=completed&_fields=subject,code,description,status,_lastUpdated`
                )
              }
            />
          </>
        )
      }
        */
    >
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <Routes>
            {profile ? (
              <>
                <Route path="/" element={<HomePage />} />
                <Route path="/messages/" element={<MessageTable />} />
                <Route path="/messages/:practitionerId" element={<Messages />} />
                <Route path="/Questionnaire/:questionnaireId" element={<QuestionnairePage />} />
                <Route path="/health-record/*">
                  <Route index element={<Navigate replace to="/health-record/lab-results" />} />
                  <Route path="lab-results/*" element={<LabResults />} />
                  <Route path="lab-results/:resultId" element={<LabResult />} />
                  <Route path="medications" element={<Medications />} />
                  <Route path="medications/:medicationId" element={<Medication />} />
                  <Route path="questionnaire-responses" element={<Responses />} />
                  <Route path="questionnaire-responses/:responseId" element={<Response />} />
                  <Route path="vaccines" element={<Vaccines />} />
                  <Route path="vaccines/:vaccineId" element={<Vaccine />} />
                  <Route path="vitals" element={<Vitals />} />
                  <Route path="vitals/:measurementId" element={<Measurement />} />
                </Route>
                <Route path="/Observation/:observationId" element={<ObservationPage />} />
                <Route path="/care-plan/*">
                  <Route index element={<Navigate replace to="/care-plan/action-items" />} />
                  <Route path="action-items" element={<ActionItems />} />
                  <Route path="action-items/:itemId" element={<ActionItem />} />
                </Route>
                <Route path="/health/*" element={<PlanDefinitionsPage />}>
                  <Route index element={<Navigate replace to="/health/Appointments" />} />
                  <Route path=":category" element={<PlanDefinitionsPage />} />
                </Route>
                <Route path="/treatment/*">
                  <Route path=":planDefinitionName" element={<PlanDefinitionViewPage />} />
                </Route>
                <Route path="/schedule/*">
                  <Route index element={<Navigate replace to="/health" />} />
                  <Route path=":planDefinitionName" element={<SchedulePage />} />
                  <Route path=":planDefinitionName/:questionnaireResponseId" element={<SchedulePage />} />
                  <Route
                    path="confirmation/QuestionnaireResponse/:resourceId/*"
                    element={<ConfirmQuestionnaireResponsePage />}
                  />
                </Route>
                <Route path="/payment/*">
                  <Route index element={<Navigate replace to="/health" />} />
                  <Route path=":questionnaireResponseId" element={<StripePage />} />
                </Route>
                <Route path="/account/*" element={<AccountPage />}>
                  <Route index element={<Navigate replace to="/account/profile" />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="provider/*" element={<Provider />} />
                  <Route path="membership-and-billing" element={<MembershipAndBilling />} />
                </Route>
                <Route path="/signout" element={<SignOutPage />} />
              </>
            ) : (
              <>
                <Route path="/signin" element={<SignInPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="*" element={<Navigate replace to="/signin" />} />
              </>
            )}
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </AppShell>
  );
}
