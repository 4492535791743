import { evalFhirPath } from '@iehr/core';
import { QuestionnaireResponseItem, Extension, Patient, Address, QuestionnaireResponse } from '@iehr/fhirtypes';

export function flattenResponse(
  items: QuestionnaireResponseItem[] | undefined
): QuestionnaireResponseItem[] {
  return !items
    ? []
    : items
        .reduce(
          (pre, cur) => (cur.item ? [...pre, ...flattenResponse(cur.item)] : [...pre, cur]),
          [] as QuestionnaireResponseItem[]
        );
}

export function flattenQuestionnaireResponse(
  items: QuestionnaireResponseItem[] | undefined
): QuestionnaireResponseItem[] {
  return !items
    ? []
    : items
        .filter((item) => item.answer?.length || item.item)
        .reduce(
          (pre, cur) => (cur.item ? [...pre, ...flattenQuestionnaireResponse(cur.item)] : [...pre, cur]),
          [] as QuestionnaireResponseItem[]
        );
}

export function removeUnanswered(items: QuestionnaireResponseItem[]): QuestionnaireResponseItem[] {
  return items
    .filter((item) => item.item || item.answer?.length)
    .map((item) => (item.item ? Object.assign(item, { item: removeUnanswered(item.item) }) : item));
}

export function fixQuestionnaireResponse(items: QuestionnaireResponseItem[]): QuestionnaireResponseItem[] {
  const patient_reference = items.find((i) => i.linkId === '_patient-reference');
  const pharmacy_reference = items.find((i) => i.linkId === '_pharmacy-reference');
  const pharmacy_name = items.find((i) => i.linkId === '_pharmacy-name');
  const pharmacy_address = items.find((i) => i.linkId === '_pharmacy-address');
  const pharmacy_email = items.find((i) => i.linkId === '_pharmacy-email');
  const pharmacy_fax = items.find((i) => i.linkId === '_pharmacy-fax');

  //items = items.filter((i) => !i.linkId.startsWith('_'));

  //check for Pharamcy
  if (pharmacy_reference) {
    items.push(JSON.parse(JSON.stringify(pharmacy_reference)));
  } else if (pharmacy_name) {
    const extension: Extension[] = pharmacy_address?.extension || [];
    const address = JSON.parse(pharmacy_address?.answer?.[0]?.valueString ?? '') as Address;

    if (pharmacy_email || pharmacy_fax) {
      extension.push({
        url: 'http://hl7.org/fhir/StructureDefinition/ContactPoint',
        valueContactPoint: {
          system: pharmacy_email ? 'email' : 'fax',
          value: pharmacy_email?.answer?.[0]?.valueString ?? pharmacy_fax?.answer?.[0]?.valueString,
        },
      });
      extension.push({
        url: 'http://hl7.org/fhir/StructureDefinition/Address',
        valueAddress: address,
      });
    }

    const qrItem: QuestionnaireResponseItem = {
      id: pharmacy_name.id,
      linkId: '_pharmacy',
      text: 'Pharmacy',
      answer: [
        {
          valueString: [
            pharmacy_name.answer?.[0]?.valueString,
            address.text,
            pharmacy_email?.answer?.[0]?.valueString,
            pharmacy_fax?.answer?.[0]?.valueString,
          ]
            .filter((p) => p)
            .join('\n'),
          extension: extension,
        },
      ],
    };

    items.unshift(qrItem);
  }

  if (patient_reference) {
    const patient = JSON.parse(patient_reference.answer?.[0]?.valueString ?? '{}') as Patient;
    const qrItem: QuestionnaireResponseItem = {
      id: patient_reference.id,
      linkId: '_patient',
      text: 'Patient',
      answer: [
        {
          valueString: [
            evalFhirPath("name.first().select( prefix| given | family |suffix ).join(' ')", patient)[0],
            evalFhirPath('Patient.birthDate', patient)[0],
            evalFhirPath('Patient.gender', patient)[0],
            evalFhirPath("telecom.where(system='phone').value", patient)[0],
            evalFhirPath("telecom.where(system='email').value", patient)[0],
            evalFhirPath('address.first().text', patient)[0],
          ]
            .filter((p) => p)
            .join('\n'),
        },
      ],
    };
    items.unshift(qrItem);
  }

  return items;
}


export function flattenFixQuestionnaireResponse(qr: QuestionnaireResponse){
  return fixQuestionnaireResponse(flattenQuestionnaireResponse(qr.item))
}