import { PlanDefinition, Questionnaire, QuestionnaireResponse, Reference, Schedule } from '@iehr/fhirtypes';
import { Document, Loading, useIEHR, useIEHRProfile } from '@iehr/react';
import { useEffect, useState } from 'react';
import { ResourceArray, evalFhirPath, normalizeErrorString } from '@iehr/core';
import { Scheduler } from './Scheduler';

export interface ScheduleViewProps {
  readonly planDefinitionName: string;
  readonly questionnaireResponseId?: string;
}

export function ScheduleView({
  planDefinitionName,
  questionnaireResponseId,
}: ScheduleViewProps): JSX.Element | null {
  const iehr = useIEHR();
  const profile = useIEHRProfile();
  const [planDefinition, setPlanDefinition] = useState<PlanDefinition>();
  const [questionnaireResponse, setQuestionnaireResponse] = useState<QuestionnaireResponse>();
  const [schedules, setSchedules] = useState<ResourceArray<Schedule>>();
  const [questionnaires, setQuestionnaires] = useState<Reference<Questionnaire>[]>();

  useEffect(() => {
    if (planDefinitionName) {
      iehr
        .searchOne('PlanDefinition', `name=${planDefinitionName}`)
        .then(setPlanDefinition)
        .catch((err) => {
          console.error(`PlanDefinition/${planDefinitionName}: ${normalizeErrorString(err)}`);
        });
    }
  }, [planDefinitionName]);

  useEffect(() => {
    if (questionnaireResponseId) {
      iehr
        .readResource('QuestionnaireResponse', questionnaireResponseId)
        .then(setQuestionnaireResponse)
        .catch((err) => {
          console.error(`QuestionnaireResponse/${questionnaireResponseId}: ${normalizeErrorString(err)}`);
        });
    }
  }, [questionnaireResponseId]);

  useEffect(() => {
    if (!planDefinition) {
      return;
    }
    const startTime = new Date();
    startTime.setHours(0, 0, 0, 0);

    const endTime = new Date(startTime.getTime());
    endTime.setDate(endTime.getDate() + 7); //only 1 week ahead
    endTime.setTime(endTime.getTime() - 1); //end of day

    const category = evalFhirPath(
      "PlanDefinition.topic.coding.where( system = 'http://onlinedoc.ie/fhir/CodeSystem/service-category').code",
      planDefinition
    )[0];
    iehr
      .searchResources(
        'Schedule',
        `service-category=${category}&date=ge${startTime.toISOString()}&date=le${endTime.toISOString()}`
      )
      .then((schedules) => {
        setSchedules(schedules);
      })
      .catch((err) => {
        throw err;
      });

    planDefinition.action &&
      setQuestionnaires(
        planDefinition.action
          .filter((a) => {
            if (a.condition?.[0].expression?.expression) {
              const path = a.condition?.[0].expression?.expression.split('.');
              if (profile && path[0] === 'Patient') {
                return !!evalFhirPath(a.condition[0].expression.expression, profile)[0];
              }
            }
            return true;
          })
          .filter((a) => a.definitionCanonical?.startsWith('Questionnaire/'))
          .map((a) => ({
            type: 'Questionnaire',
            reference: a.definitionCanonical,
          }))
      );
  }, [iehr, planDefinition]);

  if (!planDefinition || !schedules?.length) {
    return <Loading />;
  }

  return (
    <Document bg={'transparent'} bd={'none'} shadow={'none'}>
      <Scheduler
        planDefinition={planDefinition}
        schedules={schedules}
        questionnaires={questionnaires as []}
        questionnaireResponse={questionnaireResponse}
      />
    </Document>
  );
}
