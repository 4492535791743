import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { useState, useRef, useEffect } from 'react';
import { Input, TextInput } from '@mantine/core';
import { Address } from '@iehr/fhirtypes';

export interface PlaceAutocompleteProps {
  readonly label?: string;
  readonly required?: string;
  readonly defaultValue?: string;
  readonly onChange: (address: string | undefined) => void;
  readonly options: google.maps.places.AutocompleteOptions;
}

export function PlaceAutocomplete({
  defaultValue,
  label,
  required,
  onChange,
  options,
}: PlaceAutocompleteProps): JSX.Element {
  const [placeAutocomplete, setPlaceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary('places');
  const [value, setValue] = useState<string|undefined>(defaultValue);
  const [valid, setValid] = useState<boolean>(!!defaultValue);

  function placeSelect(newAddress: string | undefined) {
    setValue((previuos)=>{
      if(newAddress && previuos === newAddress){
        return;
      }
      if(newAddress?.startsWith('{"')){
        setValid(true);
        onChange(newAddress);
      }else{
        setValid(false);
      }
      
      return newAddress;
    })
    
  }
  useEffect(() => {
    if (!places || !inputRef.current) return;

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places, inputRef]);

  useEffect(() => {
    if (!placeAutocomplete) {
      //placeSelect(undefined);
      return;
    }

    placeAutocomplete.addListener('place_changed', () => {
      const place = placeAutocomplete.getPlace();
      const address: Address = {
        text: place?.address_components?.map((ac) => ac.long_name).join(', '),
        line: [
          [
            place?.address_components?.find((ac) => ac.types[0] === 'street_number')?.long_name ?? '',
            place?.address_components?.find((ac) => ac.types[0] === 'route')?.long_name ?? '',
          ]
            .filter((ac) => ac)
            .join(', '),
        ],
        city:
          place?.address_components?.find((ac) => ac.types[0] === 'postal_town')?.long_name ||
          place?.address_components?.find((ac) => ac.types[0] === 'locality')?.long_name,
        state: place?.address_components?.find((ac) => ac.types[0] === 'administrative_area_level_1')?.long_name,
        postalCode: place?.address_components?.find((ac) => ac.types[0] === 'postal_code')?.long_name,
        country: place?.address_components?.find((ac) => ac.types[0] === 'country')?.long_name,
        extension: !place?.geometry?.location
          ? undefined
          : [
              {
                url: 'http://google.com/api/places/latlng',
                valueString: place.geometry.location.toUrlValue(),
              },
            ],
      };
      placeSelect(JSON.stringify(address));
    });
  }, [placeAutocomplete]);

  return (
    <Input.Wrapper
      label={label}
      required
      error={!valid ? value?'Please enter a valid address':' ' : undefined}
      className="autocomplete-container"
    >
      <TextInput
        ref={inputRef}
        error={!valid}
        withErrorStyles={!!required}
        required={!!required}
        defaultValue={
          !defaultValue ? undefined : defaultValue.startsWith('{') ? JSON.parse(defaultValue).text : defaultValue
        }
        onChange={(e) => placeSelect(e.currentTarget.value)}
      />
    </Input.Wrapper>
  );
}
