import { Button, Grid, Paper, Stack, Table, Text, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import {
  ProfileResource,
  TypedValue,
  calculateAgeString,
  createReference,
  evalFhirPath,
  formatHumanName,
} from '@iehr/core';
import {
  Money,
  Patient,
  PlanDefinition,
  Questionnaire,
  QuestionnaireItem,
  QuestionnaireResponse,
  QuestionnaireResponseItem,
  QuestionnaireResponseItemAnswer,
  Reference,
  Schedule,
  Slot,
} from '@iehr/fhirtypes';
import {
  CalendarInput,
  QuestionnaireForm,
  useIEHR,
  QuestionnairePageSequenceProps,
  Loading,
  useIEHRProfile,
} from '@iehr/react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAvailableSlots } from './utils';
import { ReviewQuestionnaireResponse } from './ReviewQuestionnaireResponse';
import { getStartMonth } from './CalendarInput.utils';
import { PlaceAutocomplete } from './GooglePlaceAutocomplete';
import { patientReferenceFunc } from './PatientReference';
import {
  removeUnanswered,
  flattenResponse,
  flattenFixQuestionnaireResponse,
} from '../QuestionnaireResponse/questionnaire-utils';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export interface SchedulerProps {
  readonly planDefinition: PlanDefinition;
  readonly schedules: Schedule[];
  readonly questionnaires: Reference<Questionnaire>[];
  readonly questionnaireResponse?: QuestionnaireResponse;
}

export function Scheduler(props: SchedulerProps): JSX.Element | null {
  const navigate = useNavigate();
  const iehr = useIEHR();
  const profile = useIEHRProfile();
  const [source, setSource] = useState<ProfileResource>();
  const { planDefinition, schedules, questionnaires, questionnaireResponse } = props;
  const [month, setMonth] = useState<Date>(getStartMonth());
  const [date, setDate] = useState<Date>();
  const [lastDate, setLastDate] = useState<Date>();
  const [slot, setSlot] = useState<Slot>();
  const [patient, setPatient] = useState<Patient>();
  //const [pharmacy, setPharmacy] = useState<Organization>();
  const [appointmentRequired, setAppointmentRequired] = useState<boolean | undefined>(undefined);
  const [lastSlot, setLastSlot] = useState<Slot | null>();
  const [slots, setSlots] = useState<Slot[]>([]);
  const [price, setPrice] = useState<Money>();
  const [consolidatedQuestionnaire, setConsolidatedQuestionnaire] = useState<Questionnaire>();
  const [response, setResponse] = useState<QuestionnaireResponse>();
  const [lastResponse, setLastResponse] = useState<QuestionnaireResponse>();
  const [flattenedResponse, setFlattenedResponse] = useState<QuestionnaireResponseItem[]>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [day, setDay] = useState<string>();

  useEffect(() => {
    if (questionnaireResponse) {
      const schedule = questionnaireResponse?.extension?.find((qr) =>
        qr.valueReference?.reference?.startsWith('Schedule/')
      )?.valueReference as Reference<Schedule>;
      const period = questionnaireResponse?.extension?.find((qr) => qr.valuePeriod)?.valuePeriod;

      setDate(new Date(period?.start!));
      setLastDate(date);

      setSlot({
        resourceType: 'Slot',
        status: 'free',
        start: period?.start!,
        end: period?.end!,
        schedule: schedule,
      });
      setLastSlot(slot);

      setResponse(questionnaireResponse);
      setLastResponse(questionnaireResponse);
    }
    setAppointmentRequired(
      !!evalFhirPath(
        "PlanDefinition.topic.coding.where( system = 'http://onlinedoc.ie/fhir/CodeSystem/service-category').code = 'online' ",
        planDefinition
      )[0]
    );

    setPrice(planDefinition.extension?.find((ra) => ra.valueMoney)?.valueMoney);
  }, [questionnaireResponse]);

  useEffect(() => {
    if (profile && ['Patient', 'Practitioner', 'RelatedPerson'].includes(profile.resourceType)) {
      setSource(profile);
    }
  }, [profile]);

  //get all the available slots for the date
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    if (appointmentRequired === undefined || !schedules) {
      return;
    }

    (async () => {
      if (iehr && schedules && month) {
        const slots = await getAvailableSlots(iehr, schedules, planDefinition);
        setSlots(slots);

        // if no meeting required, just pick the first one, and skip the calendar view
        if (!appointmentRequired) {
          if (slots.length) {
            setDate(new Date(slots[0].start));
            setSlot(slots[0]);
          } else {
            console.log('Sorry, no time slot is available.');
            setErrorMessage('Sorry, no time slot is available.');
          }
        }
      }
    })();
  }, [iehr, schedules, month, appointmentRequired]);

  useEffect(() => {
    if (slots && questionnaires) {
      prepareQuestionnaire().then(setConsolidatedQuestionnaire);
    }
  }, [iehr, date, slots, questionnaires, source]);

  // set last date
  useEffect(() => {
    if (date !== lastDate) {
      setLastDate(date);
    }
  }, [date, lastDate]);

  // set last Slot
  useEffect(() => {
    if (slot !== lastSlot) {
      setLastSlot(slot);
    }
    if(slot){
      const date = new Date(slot.start);
      const today = new Date();
      const tomorrow = new Date(new Date());
      tomorrow.setDate(today.getDate() + 1);
      if (date.toISOString().slice(0, 10) === today.toISOString().slice(0, 10)) {
        setDay(`Today - ${date.toISOString().slice(11, 16)}` );
      } else if (date.toISOString().slice(0, 10) === tomorrow.toISOString().slice(0, 10)) {
        setDay(`Tomorrow - ${date.toISOString().slice(11, 16)}`);
      } else {
        setDay(`${date.toISOString().slice(5, 10).replace('-', '/')} - ${date.toISOString().slice(11, 16)}`);
      }      
    }

  }, [slot, lastSlot]);

  // enrich consolidatedQuestionnaire response with extra information
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      if (response !== lastResponse) {
        //add id if exists
        if (response && !response.id && lastResponse?.id) {
          response.id = lastResponse.id;
        }
        setLastResponse(response);

        if (response && slot) {
          // Delete Quesionnair field from response
          delete response.questionnaire;
          ['__date', '__time', '__review'].forEach((linkId) => {
            const index = response.item?.findIndex((i) => i.linkId === linkId);
            if (index !== undefined && index >= 0) {
              response.item?.splice(index, 1);
            }
          });

          // Set status to in-progress. After payment confirmed, should be set to 'complete'
          response.status = 'in-progress';
          source && (response.source = createReference(source));

          // Add Online Service field
          response.extension = [
            {
              url: 'http://iehr.ie/fhir/ie/iehr/StructureDefinition/ie-iehr-service-name-extension',
              valueString: planDefinition.title,
            },
            {
              url: 'http://iehr.ie/fhir/ie/iehr/StructureDefinition/ie-iehr-period-extension',
              valuePeriod: {
                start: slot.start,
                end: slot.end,
              },
            },
            {
              url: 'http://iehr.ie/fhir/ie/iehr/StructureDefinition/ie-iehr-price',
              valueMoney: price,
            },
            {
              url: 'http://iehr.ie/fhir/ie/iehr/StructureDefinition/ie-iehr-reference-extension',
              valueReference: slot.schedule,
            },
            {
              url: 'http://iehr.ie/fhir/ie/iehr/StructureDefinition/ie-iehr-reference-extension',
              valueReference: createReference(planDefinition),
            },
          ];

          response.meta = response.meta || {};
          response.meta.profile = ['http://onlinedoc.ie/fhir/StructureDefinition/questionnaire-response'];

          response.item = removeUnanswered(response.item ?? []);

          //store the response in the database
          const qr = response.id ? await iehr.updateResource(response) : await iehr.createResource(response);

          history.pushState({}, '', `/treatment/${planDefinition.id}/${qr.id}`);
          navigate(`/payment/${qr.id}`);
        }
      }
    })();
  }, [iehr, response, lastResponse, slot, props]);

  useEffect(()=>{
    if(flattenedResponse){
      //patient
      const patientStr= flattenedResponse?.find((r) => r.linkId === '_patient-reference')?.answer?.[0]?.valueString;
      if(patientStr?.length){
        const patient = JSON.parse(patientStr );
        setPatient(patient);
      }else{
        setPatient(undefined);
      }
    }
  },[flattenedResponse])
  
  const handleSetDate = useCallback((date: Date, props: QuestionnairePageSequenceProps) => {
    if (props.activePage === undefined) {
      console.log('No Active Page.', props);
      setErrorMessage('No Active Page.');
      return;
    }
    setDate(date);
    const itemResponse = props.response?.item?.find((i) => i.linkId === props.items[props.activePage!].linkId);
    itemResponse?.answer?.push({
      valueDate: date.toISOString(),
    });
    const item = props.items.find((i) => i.linkId === props.items[props.activePage!].linkId);
    item && (item.text = date.toISOString());
    props.nextStep();
  }, []);

  const handleSetSlot = useCallback((slot: Slot, props: QuestionnairePageSequenceProps) => {
    if (props.activePage === undefined) {
      console.log('No Active Page.');
      setErrorMessage('No Active Page.');
      return;
    }
    setSlot(slot);
    const itemResponse = props.response?.item?.find((i) => i.linkId === props.items[props.activePage!].linkId);
    itemResponse?.answer?.push({
      valueReference: createReference(slot),
    });
    props.nextStep();
  }, []);

  async function prepareQuestionnaireItems(item: QuestionnaireItem, parent?: QuestionnaireItem[]) {
    if (item.extension) {
      const customItem = item.extension.find((i) => i.url.includes('questionnaire-custom-item'));
      if (customItem?.valueString === 'google-places-autocomplete') {
        (customItem as any).valueString = (
          item: QuestionnaireItem,
          defaultValue: TypedValue,
          onChangeAnswer: (
            newResponseAnswer: QuestionnaireResponseItemAnswer | QuestionnaireResponseItemAnswer[]
          ) => void
        ) => (
          <PlaceAutocomplete
            defaultValue={defaultValue?.value}
            onChange={(place) => {
              onChangeAnswer(
                !place
                  ? {}
                  : {
                      valueString: place,
                    }
              );
            }}
            options={{
              types: ['address'],
              fields: ['address_components', 'geometry'],
              componentRestrictions: {
                country: ['ie'],
              },
            }}
          />
        );
      }

      if (customItem?.valueString === 'patient-reference') {
        (customItem as any).valueString = patientReferenceFunc;
      }
    }

    //recursively go through all the children
    if (item.item) {
      for (const childItem of item.item) {
        await prepareQuestionnaireItems(childItem, item.item);
      }
    }
  }

  async function prepareQuestionnaire(): Promise<Questionnaire | undefined> {
    if (!questionnaires) {
      return;
    }
    const questionnaire: Questionnaire = {
      resourceType: 'Questionnaire',
      status: 'active',
      name: 'Test',
      item: [],
    };

    if (appointmentRequired) {
      questionnaire.item?.push({
        id: 'id-cal',
        linkId: '__date',
        type: 'group',
        required: true,
        text: 'Select Date',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'http://hl7.org/fhir/questionnaire-item-control',
                  code: 'page',
                },
              ],
            },
          },
          {
            url: 'http://iehr.ie/fhir/ie/iehr/StructureDefinition/questionnaire-item',
            valueString: ((props: QuestionnairePageSequenceProps) => {
              return (
                <Stack>
                  <Title order={3}>Date</Title>
                  <CalendarInput
                    slots={slots}
                    onChangeMonth={setMonth}
                    onClick={(date) => handleSetDate(date, props)}
                  />
                </Stack>
              ) as unknown as string;
            }) as unknown as string,
          },
          {
            url: 'http://iehr.ie/fhir/ie/iehr/StructureDefinition/questionnaire-item-validator',
            valueString: ((
              item: QuestionnaireItem,
              answer: QuestionnaireResponseItemAnswer,
              response: QuestionnaireResponse
            ) => {
              //const itemResponse = response.item?.find((i) => i.linkId === item.linkId);
              if (!!date) {
                return true;
              }
              modals.open({
                title: 'No Date has been selected',
                centered: true,
                children: <Text> Please select a date. </Text>,
              });
              return false;
            }) as unknown as string,
          },
        ],
      });
    }

    if (appointmentRequired) {
      questionnaire.item?.push({
        id: 'id-time',
        linkId: '__time',
        type: 'group',
        required: true,
        text: 'Select Time',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'http://hl7.org/fhir/questionnaire-item-control',
                  code: 'page',
                },
              ],
            },
          },
          {
            url: 'http://iehr.ie/fhir/ie/iehr/StructureDefinition/questionnaire-item',
            valueString: ((props: QuestionnairePageSequenceProps) => {
              return (
                <Stack>
                  <Title order={3}>Time</Title>
                  <Grid>
                    {slots.map((s) => {
                      const slotStart = new Date(s.start as string);
                      return date
                        ? slotStart.getTime() > date.getTime() &&
                            slotStart.getTime() < date.getTime() + 24 * 3600 * 1000 && (
                              <Grid.Col span={{ base: 6, md: 4, lg: 3 }}>
                                <Button
                                  variant="outline"
                                  style={{ width: '100%' }}
                                  onClick={() => handleSetSlot(s, props)}
                                >
                                  {formatTime(slotStart)}
                                </Button>
                              </Grid.Col>
                            )
                        : null;
                    })}
                  </Grid>
                </Stack>
              );
            }) as unknown as string,
          },
          {
            url: 'http://iehr.ie/fhir/ie/iehr/StructureDefinition/questionnaire-item-validator',
            valueString: ((item: QuestionnaireItem, response: QuestionnaireResponse) => {
              //const itemResponse = response.item?.find((i) => i.linkId === item.linkId);
              if (!!slot) {
                return true;
              }
              modals.open({
                title: 'No Time has been selected',
                centered: true,
                children: <Text> Please select your prefered slot. </Text>,
              });
              return false;
            }) as unknown as string,
          },
        ],
      });
    }

/*
    questionnaire.item?.push({
      id: 'id-patient',
      linkId: '__patient',
      type: 'group',
      text: 'Review',
      extension: [
        {
          url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl',
          valueCodeableConcept: {
            coding: [
              {
                system: 'http://hl7.org/fhir/questionnaire-item-control',
                code: 'page',
              },
            ],
          },
        },
        {
          url: 'http://iehr.ie/fhir/ie/iehr/StructureDefinition/questionnaire-item',
          valueString: ((props: QuestionnairePageSequenceProps) => {
            return (
              <PatientReference 
                defaultValue={JSON.stringify(patient)}
                onPatientSelect={(p)=>setPatient(p?JSON.parse(p):un)}
              />
            );
          }) as unknown as string,
        },
      ],
    });
*/

    const groupItems: QuestionnaireItem[] = [];
    for (const pq of questionnaires) {
      const q = await iehr.readReference(pq);
      if (!q.name) {
        continue;
      }

      const parts = q.name?.split('_').filter((n) => n);
      const groupName = parts[0];
      let groupItem = groupItems.find((gi) => gi.linkId === `group-${groupName}`);
      if (!groupItem) {
        groupItem = {
          linkId: `group-${groupName}`,
          //text: q.title,
          type: 'group',
          item: [],
          extension: [
            {
              url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl',
              valueCodeableConcept: {
                coding: [
                  {
                    system: 'http://hl7.org/fhir/questionnaire-item-control',
                    code: 'page',
                  },
                ],
              },
            },
          ],
        };
        groupItems.push(groupItem);
      }

      for (const childItem of (q.item??[])) {
        await prepareQuestionnaireItems(childItem, childItem.item);
        groupItem.item?.push(childItem);
      }
    }

    groupItems?.[0]?.item?.push({
      linkId: '_patient-gender',
      type: 'string',
      text: 'Gender',
      enableWhen: [
        {
          question: 'not-exist',
          operator: 'exists',
          answerBoolean: true,
        },
      ],
    });

    questionnaire.item?.push(...groupItems);

    questionnaire.item?.push({
      id: 'id-review',
      linkId: '__review',
      type: 'group',
      text: 'Review',
      extension: [
        {
          url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl',
          valueCodeableConcept: {
            coding: [
              {
                system: 'http://hl7.org/fhir/questionnaire-item-control',
                code: 'page',
              },
            ],
          },
        },
        {
          url: 'http://iehr.ie/fhir/ie/iehr/StructureDefinition/questionnaire-item',
          valueString: ((props: QuestionnairePageSequenceProps) => {
            return (
              <Stack>
                <Title order={3}>Review</Title>
                <ReviewQuestionnaireResponse value={props.response} />
              </Stack>
            );
          }) as unknown as string,
        },
      ],
    });

    return questionnaire;
  }

  if (errorMessage) {
    return (
      <Title c="red" order={3}>
        {errorMessage}
      </Title>
    );
  }

  if (!consolidatedQuestionnaire || appointmentRequired === undefined || !slots.length || !price) {
    return <Loading />;
  }

  return (
    <>
      {errorMessage && (
        <Title c="red" order={3}>
          {errorMessage}
        </Title>
      )}
      <Grid>
        <Grid.Col span={{ base: 12, sm: 4 }}>
          <Paper shadow="xs" m="0" p="xs" bg="var(--mantine-color-appColor-8)" c='white'>
            <Table stickyHeader stickyHeaderOffset={60} >
              <Table.Tbody>
                {[
                  ['Service', `${planDefinition.title} (€${price.value})`],
                  ['Serving', day],
                  [
                    'Patient',
                    patient?.name?.[0]
                      ? formatHumanName(patient.name[0]!) +
                        (patient?.birthDate ? ` (${calculateAgeString(patient?.birthDate)})` : '')
                      : undefined,
                  ],
                  [
                    'Contact',
                    patient?.contact?.[0]?.name
                      ? formatHumanName(patient.contact[0].name!)
                      : undefined,
                  ],
                  ['Email', evalFhirPath("telecom.where(system='email').value", patient ?? {})[0]],
                  ['Email', evalFhirPath("contact.telecom.where(system='email').value", patient ?? {})[0]],
                  [
                    'Phone',
                    evalFhirPath("telecom.where(system='phone').value", patient)[0] as string
                      ? phoneUtil.formatOutOfCountryCallingNumber(
                          phoneUtil.parseAndKeepRawInput(
                            evalFhirPath("telecom.where(system='phone').value", patient)[0] as string
                          )
                        )
                      : undefined,
                  ],
                  [
                    'Phone',
                    evalFhirPath("contact.telecom.where(system='phone').value", patient)[0] as string
                      ? phoneUtil.formatOutOfCountryCallingNumber(
                          phoneUtil.parseAndKeepRawInput(
                            evalFhirPath("contact.telecom.where(system='phone').value", patient)[0] as string
                          )
                        )
                      : undefined,
                  ],
                  ['Address', evalFhirPath("address.first().text", patient ?? {})[0]],
                  ['Address', evalFhirPath("contact.address.text", patient ?? {})[0]],
                  [
                    'Pharmacy',
                    flattenedResponse?.find((r) => r.linkId === '_pharmacy')?.answer?.[0]?.valueString?.split('\n').join(', '),
                  ],
                  [
                    'Pharmacy',
                    flattenedResponse
                      ?.find((r) => r.linkId === '_pharmacy-reference')
                      ?.answer?.[0]?.valueReference?.display,
                  ],
                ]
                  .filter((r) => r[1])
                  .map((r) => (
                    <Table.Tr key={r[0] as string}>
                      <Table.Td>{r[0] as string}</Table.Td>
                      <Table.Td>{r[1] as string}</Table.Td>
                    </Table.Tr>
                  ))}
              </Table.Tbody>
            </Table>
          </Paper>
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 'auto' }}>
          <Paper shadow="xl" m="0" p="xs" >
            <QuestionnaireForm
              questionnaire={consolidatedQuestionnaire}
              response={props.questionnaireResponse}
              submitButtonText="Next"
              onSubmit={setResponse}
              onChange={(qr) => {
                if (!qr) {
                  return;
                }
                //gender
                const gender = flattenResponse(qr.item).find((i) => i.linkId === '_patient-gender');
                if (gender) {
                  gender.answer = [
                    {
                      valueString: patient?.gender,
                    },
                  ];
                }
                setTimeout(()=>setFlattenedResponse(flattenFixQuestionnaireResponse(qr)),1);
              }}
            />
          </Paper>
        </Grid.Col>
      </Grid>
    </>
  );
}



function formatTime(date: Date): string {
  return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
}
