import { Stack, Title, TitleOrder, Table } from '@mantine/core';
import { formatDate, formatDateTime } from '@iehr/core';
import { QuestionnaireResponseItem, QuestionnaireResponseItemAnswer } from '@iehr/fhirtypes';
import { CodeableConceptDisplay, QuantityDisplay, RangeDisplay, ReferenceDisplay, useIEHR } from '@iehr/react';
import { useParams } from 'react-router-dom';
import { InfoSection } from '@onlinedoc/react';

export function Response(): JSX.Element {
  const iehr = useIEHR();
  const { responseId } = useParams();
  const questionnaireResponse = iehr.searchOne('QuestionnaireResponse', `_id=${responseId}`).read();

  function  flatten (items: QuestionnaireResponseItem[] | undefined): QuestionnaireResponseItem[]{
    return !items?[]:items
        .filter((item) => item.answer?.length || item.item)
        .reduce((pre, cur) => (cur.item ? [...pre, ...flatten(cur.item)] : [...pre, cur]), [] as QuestionnaireResponseItem[])
  }

  const items = flatten(questionnaireResponse?.item);

  return (
    <InfoSection title={`${questionnaireResponse?.extension?.find((e) => e.url.includes('service-name'))?.valueString}  - ${formatDateTime(questionnaireResponse?.authored)} `}>
      <Stack>
        <Table stickyHeader stickyHeaderOffset={60}>
          <Table.Tbody>
            <Table.Tr key={'patient'}>
              <Table.Td>Patient</Table.Td>
              <Table.Td>
                <ReferenceDisplay value={questionnaireResponse?.subject} />
              </Table.Td>
            </Table.Tr>
            {items.filter(i=>!i.linkId.startsWith('_')).map((item) => (
              <ItemDisplay key={item.id} item={item} order={6} />
            ))}
          </Table.Tbody>
        </Table>
      </Stack>
    </InfoSection>
  );
}

interface ItemDisplayProps {
  item: QuestionnaireResponseItem;
  order: TitleOrder;
}

function ItemDisplay({ item, order }: ItemDisplayProps): JSX.Element {
  const { text: title, answer, item: nestedAnswers } = item;

  return answer?.[0] ? (
    <Table.Tr key={item.id}>
      <Table.Td>{title}</Table.Td>
      <Table.Td>
        <AnswerDisplay key={answer[0].id} answer={answer[0]} />
      </Table.Td>
    </Table.Tr>
  ) : (
    <></>
  );
}

interface AnswerDisplayProps {
  answer: QuestionnaireResponseItemAnswer;
}

function AnswerDisplay({ answer }: AnswerDisplayProps): JSX.Element {
  if (!answer) {
    throw new Error('No answer');
  }
  const [[key, value]] = Object.entries(answer);

  switch (key) {
    case 'valueInteger':
      return <Title order={6}>{value}</Title>;
    case 'valueQuantity':
      return <QuantityDisplay value={value} />;
    case 'valueString':
      return <Title order={6}>{value}</Title>;
    case 'valueCoding':
      return <CodeableConceptDisplay value={{ coding: [value] }} />;
    case 'valueRange':
      return <RangeDisplay value={value} />;
    case 'valueDateTime':
      return <Title order={6}>{formatDate(value)}</Title>;
    case 'valueReference':
      return <ReferenceDisplay value={value} />;
    case 'valueBoolean':
      return <Title order={6}>{value ? 'True' : 'False'}</Title>;
    default:
      return <Title order={6}>{value.toString()}</Title>;
  }
}
